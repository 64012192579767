import { ErrorMessage, Field } from "formik";

export const TextArea = ({
  children,
  type,
  name,
  label,
  placeholder,
  className,
  value,
  rows = 7,
  disabled = false,
}) => {
  return (
    <div className="outer-search mb-1">
      {label && <label htmlFor={name}>{label}</label>}
      <Field
        id={name}
        className={className}
        name={name}
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        value={value}
      />

      <ErrorMessage className="errorFeild" name={name} component="div" />
      {children}
    </div>
  );
};
