import { useParams } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { ProductLoader } from "../../utils/loaders";
import SProduct from "../../components/SProduct";

export default function SubCategory() {
  const { mcategory, sub } = useParams();
  const { data: cData } = useFetch(`category?mcat=${mcategory}&sub=${sub}`);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: cData?.categoryName, to: cData?.url },
    {
      name: "active",
      to: sub.slice(0, 1).toUpperCase().concat(sub.slice(1)),
    },
  ];

  return (
    <div>
      {cData === null ? (
        <div className="container mt-5 mb-5">
          <ProductLoader />
        </div>
      ) : (
        <>
          <div className="container">
            <Breadcrumb array={BreadcrumbData} />
          </div>
          <div className="container">
            <div className="row mb-2 promo-header-2">
              <div className="col-12 col-lg-10">
                <h1>
                  {cData?.categoryName} - {sub}{" "}
                </h1>
              </div>
            </div>

            <div className="row mb-5" id="response">
              {cData?.subCategoryItems &&
                cData?.subCategoryItems.map((item, index) => {
                  return (
                    <div className="col-lg-3 col-md-4 col-6" key={index}>
                      <SProduct item={item} key={item} />{" "}
                    </div>
                  );
                })}
              {cData?.subCategoryItems &&
                cData?.subCategoryItems.length === 0 && (
                  <div className="col-12">
                    <h3 className="text-center p-3">No items available</h3>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
