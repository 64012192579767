import { Link } from "react-router-dom";
import BModal from "../modal";
import { Button } from "../button";

export const RegisterModal = ({ show, close, logOpen }) => {
  return (
    <BModal show={show} close={close}>
      <form id="regForm">
        <input type="hidden" name="request_type" value="regUser" />

        <h3 className="heading-1 mt-2 mb-2">Create your new account!</h3>
        <div className="row mb-1">
          <div className="col-6">
            <label>First Name</label>
            <input
              className="modal-input form-control select-light"
              type="text"
              name="fname"
              placeholder="First name"
            />
          </div>
          <div className="col-6">
            <label>Last Name</label>
            <input
              className="modal-input form-control select-light"
              type="text"
              name="lName"
              placeholder="Last name"
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-12">
            <label>E-mail</label>
            <input
              className="modal-input form-control select-light"
              type="email"
              name="email"
              placeholder="E-mail"
            />
          </div>
        </div>
        <p className="color-grey">
          An auto generated password will be sent to this email address
        </p>
        <Button
          id="regBtn"
          type="button"
          className="pt-3 pb-3  mb-2 text-center"
          title={"CREATE AN ACCOUNT"}
          variant={"btn-light-green-full"}
        />

        <div className="text-center">
          <p className="mb-0">
            Already have an account?{" "}
            <Button
              onClick={logOpen}
              type="button"
              title={"Sign In"}
              variant={"btn-link"}
            />
          </p>
          <p>
            Forgotten your password?{" "}
            <Link
              title="forgot password"
              className="btn btn-link"
              to="/forgot"
              onClick={close}
            >
              Click here
            </Link>
          </p>
        </div>
      </form>
    </BModal>
  );
};
