import { Link } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { errorCode } from "../../utils";
import { ProfileMenu } from "../../components/profile-menu";
import { Badge } from "../../components/badge";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Orders() {
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "orders",
  });

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "User Profile",
      to: "/personal-details",
    },
    {
      name: "Orders",
    },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>My Orders:</h3>
              </div>
              {personalData?.message?.orders.length === 0 && (
                <div className="alert alert-warning" role="alert">
                  No Orders yet!
                </div>
              )}
              {personalData?.message?.orders.map((order, index) => {
                return (
                  <div className="orders-row" key={index}>
                    <div className="row">
                      <div className="col-3 col-sm-3 col-md-3">
                        <figure className="product-image-container">
                          <Link to={order?.link} className="product-image">
                            <LazyLoadImage src={order?.image} />
                          </Link>
                        </figure>
                      </div>
                      <div className="col-9 col-sm-6 col-md-6 align-self-center">
                        <Link to={order?.link}>
                          <h2 className="text"> {order?.title} </h2>
                          <span className="qty">Qty: {order?.quantity} </span>
                          <span className="text">
                            Order Status:{" "}
                            <span className="status">{order?.status}</span>
                          </span>
                          {order?.paymentMadeBy && (
                            <span className="text">
                              Payment by{" "}
                              <span>
                                <Badge name={`${order?.paymentMadeBy}`} />{" "}
                              </span>
                            </span>
                          )}
                        </Link>
                      </div>
                      <div className="col-sm-3 col-md-3 align-self-center">
                        <div>
                          <Link
                            to={order?.link}
                            className="btn btn-light-green mb-1"
                          >
                            VIEW ORDER
                          </Link>
                          <Link
                            to={order?.link + "/proofs"}
                            className="btn btn-light-grey-border"
                          >
                            VIEW PROOFS
                          </Link>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <hr className="mt-1 mb-3" />
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-3 col-md-3">
                        <p className="bottom-head">ORDER NO:</p>
                        <span className="bottom-value"># {order?.id} </span>
                      </div>
                      <div className="col-5 col-md-3">
                        <p className="bottom-head">ORDER PLACED: </p>
                        <span className="bottom-value">{order?.date}</span>
                      </div>
                      <div className="col-4 col-md-3">
                        <p className="bottom-head">TOTAL: </p>
                        <span className="bottom-value">
                          £{order?.totalCostIncVat}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
