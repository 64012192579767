import { Link } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { ProductLoader } from "../../utils/loaders";
import { gUrl } from "../../utils/constants";

export default function Categories() {
  const { data: categoryData } = useFetch(`general?type=categories`);
  const BreadcrumbData = [{ name: "Home", to: "/" }, { name: "Categories" }];

  return (
    <div>
      {categoryData === null ? (
        <div className="container mt-5 mb-5">
          <ProductLoader />
        </div>
      ) : (
        <>
          <div className="container">
            <Breadcrumb array={BreadcrumbData} />
          </div>
          <div className="container">
            <h2 className="cms-page-header mb-2">BROWSE OUR CATEGORIES</h2>
            <div className="row mb-2 ">
              {categoryData?.message?.categories &&
                categoryData?.message?.categories.map((item, index) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-6 mt-1 mb-2 ">
                      <div className="subBox">
                        <Link to={`/category/${item?.categoryURL}`}>
                          <img
                            className="img-responsive"
                            src={gUrl + item?.catImage}
                            alt={item?.categoryName}
                            title={item?.categoryName}
                          />
                        </Link>
                        <Link
                          className="btn"
                          to={`/category/${item?.categoryURL}`}
                        >
                          {item?.categoryName}
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
