import React, { useState, useEffect, useCallback } from "react";
import { asyncCall, authHeader, notifyFail } from "../../utils";
import { apiSrc } from "../../utils/constants";
import { Stepper } from "react-form-stepper";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { validationSchemasQuoteSteps } from "../../utils/validationSchema";

export const QuoteMultiStep = ({
  code,
  data,
  scroll,
  toggleOpenQuoteSection,
  quoteToggleModal,
}) => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    colour: "",
    quantity: "",
    branding: "",
    noOfColours: "",
    printPosition: "",
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    deliveryLocation: "UK (Exc. Highlands & Islands)",
    additional: "",
  });
  const [errors, setErrors] = useState({});
  const [brandingOptions, setBrandingOptions] = useState([]);
  const [noOfColoursOptions, setNoOfColoursOptions] = useState([]);
  const [printPositionOptions, setPrintPositionOptions] = useState([]);

  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "updateUser",
  });

  const validationSchemas = validationSchemasQuoteSteps;

  const getBranding = useCallback(
    async (quantity) => {
      const response = await asyncCall(apiSrc + "generalUpdate", "POST", {
        request_type: "getBranding",
        quantity: quantity,
        product: data?.quantity_breaks,
      });

      if (typeof response?.message?.brandingList === "string") {
        setBrandingOptions([`${response?.message?.brandingList}`] || []);
      } else {
        setBrandingOptions(response?.message?.brandingList || []);
      }
    },
    [data?.quantity_breaks]
  );

  const getColorsPosition = useCallback(async () => {
    const response = await asyncCall(apiSrc + "generalUpdate", "POST", {
      request_type: "getColorsPosition",
      quantity: values.quantity,
      branding: values.branding,
      product: data?.quantity_breaks,
    });

    if (typeof response?.message?.noOfColours === "string") {
      setNoOfColoursOptions([`${response?.message?.noOfColours}`] || []);
    } else {
      setNoOfColoursOptions(response?.message?.noOfColours || []);
    }

    if (typeof response?.message?.printPosition === "string") {
      setPrintPositionOptions([`${response?.message?.printPosition}`] || []);
    } else {
      setPrintPositionOptions(response?.message?.printPosition || []);
    }
  }, [data?.quantity_breaks, values.branding, values.quantity]);

  useEffect(() => {
    if (values.quantity) getBranding(values.quantity);
  }, [getBranding, values.quantity]);

  useEffect(() => {
    if (values.branding) {
      getColorsPosition();
      if (personalData?.status !== 423) {
        setValues((prevValues) => ({
          ...prevValues,
          companyName: personalData?.message?.address?.companyName,
          firstName: personalData?.message?.person?.fname,
          lastName: personalData?.message?.person?.lName,
          email: personalData?.message?.person?.email,
          telephone: personalData?.message?.person?.telephone,
        }));
      }
    }
  }, [
    getColorsPosition,
    personalData?.message?.address?.companyName,
    personalData?.message?.person?.email,
    personalData?.message?.person?.fname,
    personalData?.message?.person?.lName,
    personalData?.message?.person?.telephone,
    personalData?.status,
    values.branding,
  ]);

  const validate = async (schema, values) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {};
    } catch (err) {
      const errors = {};
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      return errors;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    const currentSchema = validationSchemas[step];
    const currentStepValues = getStepValues(step);
    const currentErrors = await validate(currentSchema, currentStepValues);
    setErrors(currentErrors);

    if (Object.keys(currentErrors).length === 0) {
      setStep((prevStep) => prevStep + 1);
    }
    scroll();
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
    scroll();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalSchema = validationSchemas[step];
    const finalErrors = await validate(finalSchema, values);
    setErrors(finalErrors);

    if (Object.keys(finalErrors).length === 0) {
      // Submit form data here

      fetch(`${apiSrc}generalUpdate`, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          request_type: "expressQuote",
          cid: personalData?.message?.person?.cid
            ? personalData?.message?.person?.cid
            : "",
          code: code,
          colour: values.colour,
          quantity: values.quantity,
          branding: values.branding,
          noOfColours: values.noOfColours,
          printPosition: values.printPosition,
          companyName: values.companyName,
          fname: values.firstName,
          lname: values.lastName,
          email: values.email,
          tel: values.telephone,
          deliveryLocation: values.deliveryLocation,
          additional: values.additional,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === 200) {
            //notifySuccess(data?.message);
            setStep(0);
            // clear inputs
            setValues({
              ...values,
              colour: "",
              quantity: "",
              branding: "",
              noOfColours: "",
              printPosition: "",
              deliveryLocation: "",
              additional: "",
            });

            toggleOpenQuoteSection();
            quoteToggleModal();
          } else {
            notifyFail(data?.message ? data?.message : "Something went wrong!");
          }
        })
        .catch((error) => {
          notifyFail("Something went wrong!");
          console.error(error);
        })
        .finally(() => {});
    }
  };

  const getStepValues = (step) => {
    switch (step) {
      case 0:
        return { colour: values.colour, quantity: values.quantity };
      case 1:
        return {
          branding: values.branding,
          noOfColours: values.noOfColours,
          printPosition: values.printPosition,
        };
      case 2:
        return {
          companyName: values.companyName,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          telephone: values.telephone,
          deliveryLocation: values.deliveryLocation,
          additional: values.additional,
        };
      default:
        return {};
    }
  };

  const Step1 = () => (
    <div className="row gift-finder">
      <div className="col-md-6">
        <label>Product Colour:</label>
        <select
          aria-label="colour step 1"
          name="colour"
          value={values.colour}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select color</option>
          {data?.colors.map((color, index) => (
            <option key={index} value={color}>
              {color}
            </option>
          ))}
        </select>
        {errors.colour && <div className="errorFeild">{errors.colour}</div>}
      </div>
      <div className="col-md-6">
        <label>Quantity:</label>
        <select
          aria-label="quantity step 1"
          name="quantity"
          value={values.quantity}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select quantity</option>
          {data?.ProductQuantity.map((qty1, index) => (
            <option key={index} value={qty1}>
              {qty1}
            </option>
          ))}
        </select>
        {errors.quantity && <div className="errorFeild">{errors.quantity}</div>}
      </div>
    </div>
  );

  const Step2 = () => (
    <div className="row gift-finder">
      <div className="col-md-4">
        <label>Branding:</label>
        <select
          aria-label="Branding step 2"
          name="branding"
          value={values.branding}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select branding</option>
          {brandingOptions.map((brand, index) => (
            <option key={index} value={brand}>
              {brand}
            </option>
          ))}
        </select>
        {errors.branding && <div className="errorFeild">{errors.branding}</div>}
      </div>
      <div className="col-md-4">
        <label>No Of Colours:</label>
        <select
          aria-label="noOfColours step 2"
          name="noOfColours"
          value={values.noOfColours}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select no of colours</option>
          {noOfColoursOptions.map((brand, index) => (
            <option key={index} value={brand}>
              {brand}
            </option>
          ))}
        </select>
        {errors.noOfColours && (
          <div className="errorFeild">{errors.noOfColours}</div>
        )}
      </div>
      <div className="col-md-4">
        <label>Print Position:</label>
        <select
          aria-label="printPosition step 2"
          name="printPosition"
          value={values.printPosition}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select print position</option>
          {printPositionOptions.map((brand, index) => (
            <option key={index} value={brand}>
              {brand}
            </option>
          ))}
        </select>
        {errors.printPosition && (
          <div className="errorFeild">{errors.printPosition}</div>
        )}
      </div>
    </div>
  );

  function step3() {
    return (
      <div className="row gift-finder">
        <div className="col-md-12">
          <label>Company name:</label>
          <input
            type="text"
            name="companyName"
            value={values.companyName}
            onChange={handleChange}
            className="form-control select-light"
          />
          {errors.companyName && (
            <div className="errorFeild">{errors.companyName}</div>
          )}
        </div>
        <div className="col-md-6">
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            className="form-control select-light"
          />
          {errors.firstName && (
            <div className="errorFeild">{errors.firstName}</div>
          )}
        </div>
        <div className="col-md-6">
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            className="form-control select-light"
          />
          {errors.lastName && (
            <div className="errorFeild">{errors.lastName}</div>
          )}
        </div>
        <div className="col-md-6">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            className="form-control select-light"
          />
          {errors.email && <div className="errorFeild">{errors.email}</div>}
        </div>
        <div className="col-md-6">
          <label>Telephone:</label>
          <input
            type="text"
            name="telephone"
            value={values.telephone}
            onChange={handleChange}
            className="form-control select-light"
          />
          {errors.telephone && (
            <div className="errorFeild">{errors.telephone}</div>
          )}
        </div>
        <div className="col-md-12">
          <label>Delivery Location:</label>
          <select
            aria-label="deliveryLocation step 3"
            name="deliveryLocation"
            value={values.deliveryLocation}
            onChange={handleChange}
            className="form-control select-light"
          >
            <option value="UK (Exc. Highlands & Islands)">
              UK (Exc. Highlands & Islands)
            </option>
            <option value="other">All other locations</option>
          </select>
          {errors.deliveryLocation && (
            <div className="errorFeild">{errors.deliveryLocation}</div>
          )}
        </div>
        <div className="col-md-12">
          <label>Additional:</label>
          <textarea
            name="additional"
            value={values.additional}
            onChange={handleChange}
            className="form-control"
          />
          {errors.additional && (
            <div className="errorFeild">{errors.additional}</div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="small-header header-bottom-grey">
        <h3>Please fill in the details for quick quote :</h3>
      </div>
      <Stepper
        styleConfig={{
          completedBgColor: "#5090c4",
          activeBgColor: "#26ccca",
        }}
        steps={[
          { label: "1. Product" },
          { label: "2. Branding " },
          { label: "3. Requirements" },
        ]}
        activeStep={step}
      />
      <form onSubmit={handleSubmit}>
        {step === 0 && <Step1 />}
        {step === 1 && <Step2 />}
        {step === 2 && step3()}
        <div className="container text-right mt-3">
          {step > 0 && (
            <button
              type="button"
              onClick={handlePrevious}
              className="btn btn-orange   mr-3"
            >
              Previous
            </button>
          )}
          {step < validationSchemas.length - 1 && (
            <button
              type="button"
              onClick={handleNext}
              className="btn btn-light-green "
            >
              Next
            </button>
          )}
          {step === validationSchemas.length - 1 && (
            <button type="submit" className="btn btn-light-green float-right">
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
};
