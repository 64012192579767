import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export const Logout = () => {
  const navigate = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    toast.warn("Logging out ...");
    removeCookie("token");
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 100);
  }, [navigate, isFirstRender]);

  return <div></div>;
};
