import useFetch from "../../hooks/useFetch";
import { getSlug } from "../../utils";
import BModal from "../modal";

export const QuoteModal = ({ show, close }) => {
  const { data: slugData } = useFetch(`general?type=slug`);

  var telephone = null;
  var email = null;

  if (slugData !== null) {
    telephone = getSlug(slugData, "telephone");
    email = getSlug(slugData, "email");
  }

  return (
    <BModal show={show} close={close}>
      <h3 className="heading-1 mt-2 mb-2">Request Sent!</h3>
      <p>
        Your request has been sent successfully! One of our team members will
        get back to you with the quote details shortly. Thank you!
      </p>
      <div className="text-center mb-2">
        <i
          style={{ color: "#26CCCA", width: "150px", height: "150px" }}
          data-feather="check-circle"
        ></i>
      </div>
      <p className="mb-2">
        For any queries contact us at{" "}
        <a className="btn btn-link" href={`mailto: ${email}`}>
          {email}
        </a>{" "}
        <br /> or call on{" "}
        <a className="btn btn-link" href={`tel: ${telephone}`}>
          {telephone}
        </a>
      </p>
      <button
        className="btn btn-light-green-full"
        data-dismiss="modal"
        onClick={close}
      >
        CONTINUE
      </button>
    </BModal>
  );
};
