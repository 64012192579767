import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { apiSrc, stepsTexts } from "../../utils/constants";
import { authHeader, errorCode, formatNumber, notifyFail } from "../../utils";
import { Steps } from "../../components/stepper";
import { DP } from "../../components/datepicker";
import { useEffect, useState } from "react";
import "./index.css";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";

export default function Checkout() {
  const navigate = useNavigate();
  const { data: pdata } = useFetch("personal", "POST", {
    request_type: "checkout",
  });

  const [date, setDate] = useState(null);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Checkout",
      to: "/checkout",
    },
  ];

  useEffect(() => {
    if (pdata?.message?.cartItem?.proposed_date)
      setDate(new Date(pdata?.message?.cartItem?.proposed_date));
  }, [pdata?.message?.cartItem?.proposed_date]);

  useEffect(() => {
    if (pdata !== null) setText(pdata?.message?.cartItem?.additional);
  }, [pdata]);

  function changeDate(date) {
    setDate(date);
  }

  function gotoNext() {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "checkoutUpdate",
        date,
        additional: text,
      }),
    };
    setIsLoading(true);
    fetch(`${apiSrc}personal_update`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          navigate(`/shipping`);
        } else {
          notifyFail(data?.message ? data?.message : "Something went wrong!");
        }
      })
      .catch((error) => {
        notifyFail("Something went wrong!");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {pdata?.status !== 200 ? (
            errorCode(pdata?.status, pdata?.message)
          ) : (
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Steps active={0} steps={stepsTexts} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="order-summary">
                      <h2 className="step-title mb-2">Product</h2>
                      <figure className="product-image-container pr-2">
                        <a href=" echo $url; ?> ">
                          <img
                            style={{ width: "100px" }}
                            src={pdata?.message?.product?.image}
                            alt={pdata?.message?.product?.title}
                            title={pdata?.message?.product?.title}
                          />
                        </a>
                      </figure>
                      <h2 className="product-title">
                        <a href=" echo $url; ?> ">
                          {pdata?.message?.product?.title}
                        </a>
                      </h2>
                      <div className="pt-2">
                        <p>
                          <b>Qty:</b> {pdata?.message?.cartItem?.quantity}{" "}
                          <br />
                          <b>Branding:</b> {pdata?.message?.cartItem?.branding}{" "}
                          <br />
                          <b>Product Colour:</b>{" "}
                          {pdata?.message?.cartItem?.colour}
                          <br />
                          <b>No of Colours:</b>{" "}
                          {pdata?.message?.cartItem?.noOfColours}
                          <br />
                          <b>Print Position:</b>{" "}
                          {pdata?.message?.cartItem?.printPosition} <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-summary">
                      <h3>Summary</h3>
                      <table className="table table-cart-pricing mt-2 mb-0">
                        <thead>
                          <tr>
                            <th>Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Pricing</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span id="quantity2Span">
                                {pdata?.message?.cartItem?.quantity}
                              </span>
                            </td>
                            <td className="text-right">
                              £
                              <span id="color_costSpan">
                                {pdata?.message?.cartItem?.color_cost}
                              </span>
                            </td>
                            <td className="text-right">
                              £
                              <span id="SubTotalEachSpan">
                                {formatNumber(
                                  parseFloat(
                                    pdata?.message?.cartItem?.quantity
                                  ) *
                                    parseFloat(
                                      pdata?.message?.cartItem?.color_cost
                                    )
                                )}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td className="header text-right">Origination:</td>
                            <td className="text-right">
                              £
                              <span id="originationSpan">
                                {formatNumber(
                                  parseFloat(
                                    pdata?.message?.cartItem?.origination
                                  )
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td className="header text-right">Delivery:</td>
                            <td className="text-right">
                              £
                              <span id="carriage_costSpan">
                                {formatNumber(
                                  parseFloat(
                                    pdata?.message?.cartItem?.carriage_cost
                                  )
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td className="header text-right">Sub-total:</td>
                            <td className="text-right">
                              £
                              <span id="totalCostExVatSpan">
                                {formatNumber(
                                  parseFloat(
                                    pdata?.message?.cartItem?.totalCostExVat
                                  )
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td className="header text-right">VAT 20%</td>
                            <td className="text-right">
                              £
                              <span id="vatSpan">
                                {formatNumber(
                                  parseFloat(pdata?.message?.cartItem?.vat)
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr style={{ borderTop: "2px solid #39C4BA" }}>
                            <td></td>
                            <td className="primary-header text-right">
                              Total:
                            </td>
                            <td className="primary-header text-right">
                              £
                              {formatNumber(
                                parseFloat(
                                  pdata?.message?.cartItem?.totalCostIncVat
                                )
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <p className="pt-5">
                      Rest assured, we will never proceed to production until
                      you are completely happy with the proposed design layout
                      and have approved the PDF proof that we will provide.
                    </p>
                    <h3 className="mb-1">Additional Information</h3>
                    <div className=" product-single-details pl-0 mb-1">
                      <div>
                        <form className="mb-1" id="additionalform">
                          <input
                            type="hidden"
                            name="request_type"
                            value="updateAdditional"
                          />
                          <label>Delivery Date (Optional):</label>
                          <br />
                          <DP changeDate={changeDate} value={date} />
                          <textarea
                            name="additional"
                            rows="6"
                            placeholder="Please add any further information here."
                            onChange={(e) => setText(e.target.value)}
                            value={text}
                          ></textarea>
                        </form>
                      </div>
                    </div>
                    <Button
                      className="pt-2 pb-2 text-center mb-2"
                      variant={"btn-light-green-full"}
                      type="button"
                      title={"Proceed to billing"}
                      onClick={gotoNext}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
