import { useParams } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { ProfileMenu } from "../../components/profile-menu";
import { errorCode } from "../../utils";
import { gUrl } from "../../utils/constants";
import { badgeGeneral } from "../../components/badge";
import { useState } from "react";
import { ApproveModal } from "../../components/modals/approve";

export default function Proofs() {
  const { order } = useParams();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "getproofs",
    orderId: order,
  });

  const [mshow, setMShow] = useState(false);
  const [selectedID, setSelectedID] = useState(0);
  const modalShow = (id) => {
    setSelectedID(id);
    setMShow(true);
  };
  const modalClose = () => setMShow(false);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "User Profile",
      to: "/personal-details",
    },
    {
      name: "Orders",
      to: "/orders",
    },
    {
      name: "Order #" + order,
      to: "/orders",
    },
    {
      name: "proofs",
    },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Proofs available for order #{order}: </h3>
              </div>
              <div className="order-summary mt-0">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-res table-bordered table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Version</th>
                          <th scope="col">Ref #:</th>
                          <th scope="col">Action</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {personalData?.message?.proofs.map((item, index) => (
                          <>
                            <tr>
                              <td
                                style={{ verticalAllign: "middle" }}
                                data-label="Version"
                              >
                                v{item?.count}
                              </td>
                              <td data-label="Ref #:">{item?.id} </td>
                              <td data-label="Action">
                                <button
                                  onClick={() =>
                                    window.open(
                                      `${gUrl + item.filepath}`,
                                      "popup",
                                      "width=600,height=800"
                                    )
                                  }
                                  className="btn btn-sm btn-orange"
                                >
                                  <i className="fa fa-eye"></i> View document{" "}
                                </button>
                              </td>
                              <td data-label="Status">
                                {item?.status ? (
                                  badgeGeneral(item?.status)
                                ) : (
                                  <button
                                    onClick={() => modalShow(item?.id)}
                                    type="button"
                                    className="btn btn-sm btn-light-green"
                                  >
                                    Approve / Disapprove
                                  </button>
                                )}
                              </td>
                            </tr>
                            {item?.adminNotes && (
                              <tr>
                                <td
                                  data-label="Note"
                                  colspan="4"
                                  className="table-active"
                                >
                                  Note: {item?.adminNotes}
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                        {personalData?.message?.proofs.length === 0 && (
                          <tr>
                            <td colspan="4" className="table-active">
                              No Proofs available for this order yet.
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
      <ApproveModal
        show={mshow}
        close={modalClose}
        oid={order}
        proofId={selectedID}
      />
    </div>
  );
}
