import { Link, useParams } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { gUrl } from "../../utils/constants";

export default function Blog() {
  const { blog } = useParams();
  const { data: pdata } = useFetch(`general?type=blog&url=${blog}`);
  const pagetitle = pdata ? pdata?.message?.blog?.title : "";

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Blogs",
      to: "/blogs",
    },
    {
      name: pagetitle,
    },
  ];

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <div className="container-fluid red-triangle-right">
          <div className="container pt-5">
            <div className="row mb-4">
              <div className="col-12">
                <img
                  className="img-fluid"
                  src={gUrl + pdata?.message?.blog?.image}
                  alt={pdata?.message?.blog?.title}
                  title={pdata?.message?.blog?.title}
                />
              </div>
            </div>
            <h1 className="cms-page-header mb-2">
              {pdata?.message?.blog?.title}
            </h1>
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: pdata?.message?.blog?.description,
              }}
            />

            <div className="mb-5">
              <Link
                title="blogs"
                className="btn btn-light-green text-center"
                to="/blogs"
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
