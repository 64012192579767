import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb({ array }) {
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-nav mt-0">
      <ol className="breadcrumb">
        {array.map((item, index) => {
          if (item?.name === "active") {
            return (
              <li
                className="breadcrumb-item active"
                aria-current="page"
                key={index}
              >
                {item?.to}
              </li>
            );
          }

          return (
            <li className="breadcrumb-item" key={index}>
              <Link title={item?.name} to={item?.to}>
                {item?.name}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
