import { Link, useParams, useNavigate } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import {
  authHeader,
  btWarning,
  dateFormat,
  errorCode,
  MySwal,
  notifyFail,
  notifySuccess,
} from "../../utils";
import { ProfileMenu } from "../../components/profile-menu";
import { apiSrc } from "../../utils/constants";
import { Paypal } from "../../components/paypal";

export default function QuotePayment() {
  const { quote, subId } = useParams();
  const navigate = useNavigate();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "quote_payment",
    quote: quote,
    subId: subId,
  });
  console.log(personalData);
  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "User Profile",
      to: "/personal-details",
    },
    {
      name: "Quotes",
      to: "/quotes",
    },
    {
      name: "Quote #" + quote,
      to: "/quote/" + quote,
    },
    {
      name: "Version " + subId,
    },
  ];

  function onAccount() {
    MySwal.fire(btWarning).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            request_type: "buyOnAccount",
            selected: subId,
          }),
        };
        fetch(`${apiSrc}personal_update`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === 200) {
              notifySuccess("Order has been placed succesfully!");
              navigate("/quote/" + quote);
            } else {
              notifyFail(
                data?.message ? data?.message : "Something went wrong!"
              );
            }
          })
          .catch((error) => {
            notifyFail("Something went wrong!");
            console.error(error);
          })
          .finally(() => {});
      }
    });
  }

  function onApprovePaypal(data) {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "PCQ",
        details: data,
        selected: subId,
        quoteID: quote,
      }),
    };
    fetch(`${apiSrc}personal_update`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          notifySuccess("Order has been placed succesfully!");
          navigate("/quote/" + quote);
        } else {
          notifyFail(data?.message ? data?.message : "Something went wrong!");
        }
      })
      .catch((error) => {
        notifyFail("Something went wrong!");
        console.error(error);
      })
      .finally(() => {});
  }

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Quote Details:</h3>
              </div>
              <div className="order-summary mt-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Details: </h3>
                      <address>
                        <b>
                          Quote No: #{personalData?.message?.orderArray?.id}-{" "}
                          {subId}
                          <br />
                          Quote Placed:{" "}
                          {dateFormat(
                            personalData?.message?.orderArray?.order_date
                          )}
                        </b>
                      </address>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="collapse show" id="order-cart-section">
                      <table className="table table-mini-cart">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="product-col">
                              <figure className="product-image-container">
                                <Link to="#" className="product-image">
                                  <img
                                    src={personalData?.message?.product?.image}
                                    alt="product"
                                  />
                                </Link>
                              </figure>
                            </td>
                            <td>
                              <div>
                                <h2 className="product-title">
                                  {personalData?.message?.product?.title}
                                </h2>
                                <span className="product-qty">
                                  Code: {personalData?.message?.product?.code}
                                </span>
                                <span className="product-qty">
                                  Qty:{" "}
                                  {personalData?.message?.orderArray?.quantity}
                                </span>
                              </div>
                            </td>
                            <td>
                              <p>
                                <b>Branding:</b>{" "}
                                {personalData?.message?.orderArray?.branding}
                                <br />
                                <b>Product Colour:</b>{" "}
                                {personalData?.message?.orderArray?.colour}
                                <br />
                                <b>No of Colours:</b>{" "}
                                {personalData?.message?.orderArray?.noOfColours}
                                <br />
                                <b>Print Position:</b>{" "}
                                {
                                  personalData?.message?.orderArray
                                    ?.printPosition
                                }
                                <br />
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {personalData?.message?.quotePdf?.paidby === "" ? (
                        <div className="col-md-6">
                          <div className="checkout-info-box mt-1">
                            <h3 className="step-title">
                              Ready to order? Pay here:
                            </h3>
                            {personalData?.message?.buyOnAccount && (
                              <>
                                <p className="text-center mb-0">OR</p>
                                <Paypal onApprove={onApprovePaypal} />
                                <p className="text-center mb-0">OR</p>
                                <button
                                  className="btn btn-orange-full p-3 mt-1"
                                  onClick={onAccount}
                                >
                                  <i className="far fa-building"></i> Buy On
                                  Account
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <h3
                          className="step-title p-3"
                          style={{ color: "green" }}
                        >
                          This quote has been converted to an order!
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-left">
                <button
                  className="btn btn-orange mb-1"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon-angle-double-left"></i>Back
                </button>
              </p>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
