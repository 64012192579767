import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { gUrl } from "./constants";

export function getSlug(slugs, slugName) {
  return (
    slugs.message.slugs.length > 0 &&
    slugs.message.slugs
      .map((snippet) => {
        if (snippet.snippetSlug === slugName) {
          return snippet.snippetValue;
        } else {
          return undefined;
        }
      })
      .filter((slug) => slug !== undefined)[0]
  );
}

export function dateFormat(dateString) {
  const dateObject = new Date(dateString);
  const formattedDate = `${dateObject.getDate().toString().padStart(2, "0")}/${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${dateObject.getFullYear()}`;
  return formattedDate;
}

export function convertDateStringToDate(dateString) {
  const [day, month, year] = dateString.split("/");
  const date = new Date(year, month - 1, day);
  return date;
}

export function getRatingAvg(ratingArray) {
  if (ratingArray.length === 0) return 0;
  else
    return (
      ratingArray.reduce((acc, current) => acc + current.ratingValue, 0) /
      ratingArray.length
    );
}

export function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Set the expiration date to 30 days from now
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const getCookie = (name) => {
  const cookies = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));

  return cookies ? cookies.split("=")[1] : null;
};

export const removeCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const errorCode = (code, message) => {
  return (
    <div className="notfound">
      <div className="notfound-404">
        <h1>{code}</h1>
      </div>
      <h2>{message}</h2>
      <br />
      <Link title="homepage" to="/">
        <span className="arrow"></span>Return To Homepage
      </Link>
    </div>
  );
};

export const results = (message) => {
  return (
    <div className="notfound">
      <h2>{message}</h2>
      <br />
      {/* <Link to="/">
        <span className="arrow"></span>Return To Homepage
      </Link> */}
    </div>
  );
};

export function authHeader() {
  return {
    Auth: `${getCookie("token")}`,
    "Content-Type": "application/json",
  };
}

export const fetchAddresses = async (searchTerm) => {
  const apiKey = "PCWMZ-KR8QZ-MXZNQ-MPKK9";
  const countrycode = "GB";
  const endpoint = `https://ws.postcoder.com/pcw/${apiKey}/address/${countrycode}/${searchTerm}?format=json&lines=2`;
  const response = await fetch(endpoint);
  const data = await response.json();
  return data;
};

export const notifySuccess = (message) => toast.success(message);
export const notifyFail = (text) => toast.error(text);

export const MySwal = withReactContent(Swal);
export const deleteWarning = {
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#26ccca",
  cancelButtonColor: "#ea5921",
  confirmButtonText: "Yes, delete it!",
};

export const btWarning = {
  title: "One last thing:",
  text: "Please select Bank Transfer as your payment method only if you do not have a credit account with Mindvision and you intend to pay by bank transfer once your order is confirmed.",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#26ccca",
  cancelButtonColor: "#ea5921",
  confirmButtonText: "Yes, proceed!",
};

export const getFileExtension = (filename) => {
  const parts = filename.split(".");
  if (parts.length > 1) {
    return parts.pop();
  } else {
    return "";
  }
};

export const getSequence = (num) => {
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: 5,
    useGrouping: false,
  });
};

export function opedPDF(pdfFile) {
  const pdf = gUrl + pdfFile;
  const pdfWindow = window.open("", "popup", "width=800,height=1000");
  pdfWindow.document.write(
    `<iframe src="${pdf}" frameborder="0" width="100%" height="100%"></iframe>`
  );
  pdfWindow.document.close();
}

export async function asyncCall(link, type = "GET", data = {}) {
  try {
    const endpoint = `${link}`;
    const headers = {
      "Content-Type": "application/json",
    };
    let response;

    if (type.toUpperCase() === "GET") {
      response = await fetch(endpoint);
    } else {
      response = await fetch(endpoint, {
        method: type.toUpperCase(),
        headers,
        body: JSON.stringify(data),
      });
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    //throw error;
  }
}

export function cBR(name) {
  if (name && name !== "") {
    return (
      <>
        {name} <br />
      </>
    );
  } else {
    return name;
  }
}

export function formatNumber(num) {
  if (typeof num !== "number") {
    throw new Error("Input must be a number");
  }
  return num.toFixed(2);
}

export const scrollHandle = (ref) => {
  const target = ref?.current;
  if (target) target.scrollIntoView({ behavior: "smooth" });
};

export function formatStringLowercasewithdash(str) {
  return str.replace(/\s+/g, "-").toLowerCase();
}
