import { Link, useLocation } from "react-router-dom";
import { ProductLoader } from "../../utils/loaders";
import Breadcrumb from "../breadcrumb";
import useFetch from "../../hooks/useFetch";

export const ProfileMenu = ({ children, BreadcrumbData }) => {
  const { data: menuData } = useFetch(`general?type=menu`);
  const location = useLocation();
  const pathname = "/" + location.pathname.substring(1);

  const content = menuData
    ? menuData?.message?.menu.filter(
        (item) => item.title === "User Profile Menu"
      )[0].content
    : [];

  return (
    <div>
      {menuData === null ? (
        <div className="container mt-5 mb-5">
          <ProductLoader />
        </div>
      ) : (
        <>
          <div className="container">
            <Breadcrumb array={BreadcrumbData} />
            <div className="container profile">
              <div className="row">
                <aside className="sidebar col-lg-3">
                  <div className="widget widget-dashboard">
                    <h3 className="widget-title">My Account</h3>
                    <ul id="userMenu" className="list">
                      {JSON.parse(content).map((item) => {
                        return (
                          <li
                            className={pathname === item?.href ? "active" : ""}
                            key={item?.href}
                          >
                            <Link title={item?.text} to={item?.href}>
                              {item?.text}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </aside>
                <div className="col-lg-9 order-lg-last dashboard-content">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
