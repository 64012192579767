import { Link, useParams, useNavigate } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { cBR, dateFormat, errorCode, formatNumber } from "../../utils";
import { ProfileMenu } from "../../components/profile-menu";
import { Badge } from "../../components/badge";
import { gUrl } from "../../utils/constants";

export default function Order() {
  const { order } = useParams();
  const navigate = useNavigate();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "order",
    order: order,
  });

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "User Profile",
      to: "/personal-details",
    },
    {
      name: "Orders",
      to: "/orders",
    },
    {
      name: order,
    },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Order Details:</h3>
              </div>
              <div className="order-summary mt-0">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Delivery Address: </h3>
                      <address>
                        <b>
                          {personalData?.message?.shippingAddress?.firstname}{" "}
                          {personalData?.message?.shippingAddress?.lastname}
                        </b>
                        <br />
                        {cBR(personalData?.message?.shippingAddress?.company)}
                        {cBR(personalData?.message?.shippingAddress?.address1)}
                        {cBR(personalData?.message?.shippingAddress?.address2)}
                        {cBR(personalData?.message?.shippingAddress?.town)}
                        {cBR(personalData?.message?.shippingAddress?.county)}
                        {cBR(personalData?.message?.shippingAddress?.postcode)}
                        United Kingdom <br />
                        Email:{" "}
                        <a
                          href={`mailto: ${personalData?.message?.shippingAddress?.email}`}
                        >
                          {personalData?.message?.shippingAddress?.email}
                        </a>
                        <br />
                        Tel: {personalData?.message?.shippingAddress?.phone}
                      </address>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Invoice Address: </h3>
                      <address>
                        <b>
                          {personalData?.message?.billingAddress?.firstname}{" "}
                          {personalData?.message?.billingAddress?.lastname}
                        </b>{" "}
                        <br />
                        {cBR(personalData?.message?.billingAddress?.company)}
                        {cBR(personalData?.message?.billingAddress?.address1)}
                        {cBR(personalData?.message?.billingAddress?.address2)}
                        {cBR(personalData?.message?.billingAddress?.town)}
                        {cBR(personalData?.message?.billingAddress?.county)}
                        {cBR(personalData?.message?.billingAddress?.postcode)}
                        United Kingdom <br />
                        <a
                          href={`mailto: ${personalData?.message?.billingAddress?.email}`}
                        >
                          {personalData?.message?.billingAddress?.email}
                        </a>
                        <br />
                        Tel: {personalData?.message?.billingAddress?.phone}
                      </address>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Order Details:</h3>

                      <address>
                        <b>
                          Order No: #
                          {cBR(personalData?.message?.orderArray?.id)}
                          Order Placed:{" "}
                          {cBR(
                            dateFormat(
                              personalData?.message?.orderArray?.order_date
                            )
                          )}
                          Order Status:{" "}
                          <span className="color-orange">
                            {personalData?.message?.orderArray?.orderStatus}
                          </span>{" "}
                          {personalData?.message?.orderArray?.orderStatus ===
                            "Awaiting Artwork" && (
                            <>
                              Please email artwork file to{" "}
                              <a
                                className="btn btn-link p-0"
                                href={`mailto:production@mindvision.co.uk?subject=Order #${personalData?.message?.orderArray?.id} Artwork`}
                              >
                                production@mindvision.co.uk
                              </a>
                              <br />
                            </>
                          )}
                          {personalData?.message?.orderArray?.paymentMadeBy && (
                            <>
                              <br />
                              <span className="text">
                                Payment by{" "}
                                <span>
                                  <Badge
                                    name={`${personalData?.message?.orderArray?.paymentMadeBy}`}
                                  />
                                </span>
                              </span>
                            </>
                          )}
                          {(personalData?.message?.orderArray?.paymentMadeBy ===
                            "Bank Transfer" ||
                            personalData?.message?.orderArray?.paymentMadeBy ===
                              "Cash" ||
                            personalData?.message?.orderArray?.paymentMadeBy ===
                              "Card by Phone" ||
                            personalData?.message?.orderArray?.paymentMadeBy ===
                              "Cheque") &&
                            personalData?.message?.orderArray?.paymentStatus !==
                              "Paid" && (
                              <>
                                {/* "todo: payment link" */}
                                <Link
                                  className="btn btn-dark-green"
                                  to=" 
									 $payment_id = base64_encode($orderArray['id']); 
									 echo '/payment/'.$payment_id ;  "
                                >
                                  Click here to pay
                                </Link>
                              </>
                            )}
                          {personalData?.message?.orderArray?.paymentStatus ===
                          "Paid" ? (
                            <img
                              style={{ width: "173px" }}
                              src={`${gUrl}/assets/images/paid.png`}
                              alt="paid"
                              title="paid"
                            />
                          ) : personalData?.message?.orderArray
                              ?.paymentMadeBy !== "On Account" ? (
                            <img
                              style={{ width: "135px" }}
                              src={`${gUrl}/assets/images/notPaid.png`}
                              alt="not paid"
                              title="not paid"
                            />
                          ) : (
                            <></>
                          )}
                        </b>
                      </address>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="collapse show" id="order-cart-section">
                      <table className="table table-mini-cart">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="product-col">
                              <figure className="product-image-container">
                                <Link to="#" className="product-image">
                                  <img
                                    src={personalData?.message?.product?.image}
                                    alt={personalData?.message?.product?.title}
                                    title={
                                      personalData?.message?.product?.title
                                    }
                                  />
                                </Link>
                              </figure>
                            </td>
                            <td>
                              <div>
                                <h2 className="product-title">
                                  {personalData?.message?.product?.title}
                                </h2>
                                <span className="product-qty">
                                  Code: {personalData?.message?.product?.code}
                                </span>
                                <span className="product-qty">
                                  Qty:{" "}
                                  {personalData?.message?.orderArray?.quantity}
                                </span>
                              </div>
                            </td>
                            <td>
                              <p>
                                <b>Branding:</b>{" "}
                                {personalData?.message?.orderArray?.branding}
                                <br />
                                <b>Product Colour:</b>{" "}
                                {personalData?.message?.orderArray?.colour}
                                <br />
                                <b>No of Colours:</b>{" "}
                                {personalData?.message?.orderArray?.noOfColours}
                                <br />
                                <b>Print Position:</b>{" "}
                                {
                                  personalData?.message?.orderArray
                                    ?.printPosition
                                }
                                <br />
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <table className="table table-cart-pricing mt-2 mb-0">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Qty</th>
                          <th className="text-right">Unit Price</th>
                          <th className="text-right">Pricing</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="header">Product Price:</td>
                          <td>
                            <span id="quantity2Span">
                              {personalData?.message?.orderArray?.quantity}
                            </span>
                          </td>
                          <td className="text-right">
                            £
                            <span id="color_costSpan">
                              {personalData?.message?.orderArray?.color_cost}
                            </span>
                          </td>
                          <td className="text-right">
                            £
                            <span id="SubTotalEachSpan">
                              {formatNumber(
                                Number(
                                  personalData?.message?.orderArray?.quantity *
                                    personalData?.message?.orderArray
                                      ?.color_cost
                                )
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="header text-right">Origination:</td>
                          <td className="text-right">
                            £
                            <span id="originationSpan">
                              {formatNumber(
                                Number(
                                  personalData?.message?.orderArray?.origination
                                )
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="header text-right">Delivery:</td>
                          <td className="text-right">
                            £
                            <span id="carriage_costSpan">
                              {formatNumber(
                                Number(
                                  personalData?.message?.orderArray
                                    ?.carriage_cost
                                )
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="header text-right">Sub-total:</td>
                          <td className="text-right">
                            £
                            <span id="totalCostExVatSpan">
                              {formatNumber(
                                Number(
                                  personalData?.message?.orderArray
                                    ?.totalCostExVat
                                )
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="header text-right">VAT 20%</td>
                          <td className="text-right">
                            £
                            <span id="vatSpan">
                              {formatNumber(
                                Number(personalData?.message?.orderArray?.vat)
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="primary-header text-right">Total:</td>
                          <td className="primary-header text-right">
                            £
                            {formatNumber(
                              Number(
                                personalData?.message?.orderArray
                                  ?.totalCostIncVat
                              )
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <p className="text-left">
                <button
                  className="btn btn-orange mb-1"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon-angle-double-left"></i>Back
                </button>
              </p>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
