import React, { useState } from "react";
import { apiSrc, gUrl } from "../../utils/constants";
import useFetch from "../../hooks/useFetch";
import { getSlug, notifyFail, notifySuccess } from "../../utils";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import { InputField } from "../input-field";
import { Form, Formik } from "formik";
import { validationSchemaSignUp } from "../../utils/validationSchema";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MockMenu } from "../../BFF/Mocks/mocks";

function Footer() {
  const [isLoading, setIsLoading] = useState(false);
  const { data: slugData } = useFetch(`general?type=slug`);
  const { data: menuData } = useFetch(`general?type=menu`);

  const aboutItemcontent = menuData
    ? menuData?.message?.menu.filter((item) => item.title === "About Us")[0]
        .content
    : [];
  const CustomerItemcontent = menuData
    ? menuData?.message?.menu.filter(
        (item) => item.title === "Customer Care"
      )[0].content
    : [];
  const PromotionalItemcontent = menuData
    ? menuData?.message?.menu.filter(
        (item) => item.title === "Promotional Merchandise"
      )[0].content
    : [];

  const aboutItem =
    aboutItemcontent.length > 0
      ? JSON.parse(aboutItemcontent)
      : JSON.parse(
          MockMenu?.message?.menu.filter((item) => item.title === "About Us")[0]
            .content
        );
  const customerItem =
    CustomerItemcontent.length > 0
      ? JSON.parse(CustomerItemcontent)
      : JSON.parse(
          MockMenu?.message?.menu.filter(
            (item) => item.title === "Customer Care"
          )[0].content
        );
  const promotionalItem =
    PromotionalItemcontent.length > 0
      ? JSON.parse(PromotionalItemcontent)
      : JSON.parse(
          MockMenu?.message?.menu.filter(
            (item) => item.title === "Promotional Merchandise"
          )[0].content
        );

  var telephone = null;
  var email = null;
  var address = null;

  if (slugData !== null) {
    telephone = getSlug(slugData, "telephone");
    email = getSlug(slugData, "email");
    address = getSlug(slugData, "address");
  }

  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="newsletter-widget row row-sm align-items-center">
              <div className="col-md-12">
                <h3>
                  Sign up to keep up with all the latest product offers and
                  news.
                </h3>
                <Formik
                  initialValues={{
                    request_type: "newsletter",
                    fname: "",
                    lname: "",
                    email: "",
                    companyName: "",
                    day: "",
                    month: "",
                  }}
                  validationSchema={validationSchemaSignUp()}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    const payload = values;

                    setIsLoading(true);
                    fetch(`${apiSrc}generalUpdate`, {
                      method: "POST",
                      body: JSON.stringify(payload),
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        if (data?.status === 200) {
                          notifySuccess(data?.message);
                          resetForm();
                        } else {
                          notifyFail(
                            data?.message
                              ? data?.message
                              : "Something went wrong!"
                          );
                        }
                      })
                      .catch((error) => {
                        notifyFail("Something went wrong!");
                        console.error(error);
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                >
                  <Form className="m-0">
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <InputField
                          name={"fname"}
                          type={"text"}
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <InputField
                          name={"lname"}
                          type={"text"}
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <InputField
                          name={"email"}
                          type={"email"}
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <InputField
                          type={"text"}
                          name={"companyName"}
                          placeholder="Company Name"
                        />
                      </div>
                      <div className="col-md-3 col-lg-4">
                        <InputField
                          type={"text"}
                          name={"day"}
                          placeholder="Birthday (DD)"
                        />
                      </div>
                      <div className="col-md-3 col-lg-4">
                        <InputField
                          type={"text"}
                          name={"month"}
                          placeholder="Birthday (MM)"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Button
                          type="submit"
                          title={"Submit"}
                          variant={"btn-light-green"}
                          isLoading={isLoading}
                          iconRight
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-middle">
            <div className="row row-sm">
              <div className="col-md-6 col-lg-3">
                <div className="widget">
                  <div className="widget-content">
                    <LazyLoadImage
                      className=" pr-5 pb-5 lazyload"
                      src={`${gUrl}/assets/images/logos/promotional-gifts-logo.png`}
                      alt="Mindvision media ltd Logo"
                      title="Mindvision media ltd Logo"
                      width={"188px"}
                      height={"65.8px"}
                    />
                    <div
                      className="mb-2"
                      dangerouslySetInnerHTML={{
                        __html: address,
                      }}
                    />
                    <div className="contact mt-2">
                      T: <a href={"tel:" + telephone}>{telephone}</a>
                      <br />
                      E: <a href={"mailto:" + email}>{email}</a>
                    </div>
                  </div>
                  <div className="widget-content">
                    <div className="social-icons mt-2">
                      <a
                        href="https://www.facebook.com/mindvisionuk/?fref=ts"
                        rel="noreferrer"
                        target="_blank"
                        className="social-icon"
                        aria-label="Visit our Facebook page"
                      >
                        <FeatherIcon
                          style={{ color: "#39C4BA" }}
                          icon="facebook"
                        />
                      </a>
                      <a
                        href="https://twitter.com/MindvisionUK"
                        rel="noreferrer"
                        target="_blank"
                        className="social-icon"
                        aria-label="Visit our twitter page"
                      >
                        <FeatherIcon
                          style={{ color: "#39C4BA" }}
                          icon="twitter"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/mindvision-media-limited"
                        rel="noreferrer"
                        target="_blank"
                        className="social-icon"
                        aria-label="Visit our linkedin page"
                      >
                        <FeatherIcon
                          style={{ color: "#39C4BA" }}
                          icon="linkedin"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/mindvisionuk/"
                        rel="noreferrer"
                        target="_blank"
                        className="social-icon"
                        aria-label="Visit our instagram page"
                      >
                        <FeatherIcon
                          style={{ color: "#39C4BA" }}
                          icon="instagram"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="widget">
                  <h3 className="widget-title">About</h3>
                  <div className="widget-content">
                    <ul id="aboutMenu">
                      {aboutItem.map((item) => {
                        return (
                          <li className="" key={item.href}>
                            <Link title={item.text} to={item.href} className="">
                              <i className="empty"></i> {item.text}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="widget">
                  <h3 className="widget-title">Customer Care</h3>
                  <div className="widget-content">
                    <ul id="cusCareMenu">
                      {customerItem.map((item) => {
                        if (
                          item.text === "Sitemap" ||
                          item.text === "CSR Policy"
                        ) {
                          return (
                            <li className="" key={item.href}>
                              <a
                                title={item.text}
                                href={item.href}
                                className=""
                              >
                                <i className="empty"></i> {item.text}
                              </a>
                            </li>
                          );
                        } else
                          return (
                            <li className="" key={item.href}>
                              <Link
                                title={item.text}
                                to={item.href}
                                className=""
                              >
                                <i className="empty"></i> {item.text}
                              </Link>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="widget">
                  <h3 className="widget-title">Promotional Merchandise</h3>
                  <div className="widget-content">
                    <ul id="promoMenu">
                      {promotionalItem.map((item) => {
                        return (
                          <li className="" key={item.href}>
                            <Link title={item.text} to={item.href} className="">
                              <i className="empty"></i> {item.text}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <LazyLoadImage
                    className="img-fluid mt-3 mb-3"
                    style={{ width: "195px" }}
                    src={`${gUrl}/assets/images/logos/bpma.svg`}
                    alt="bpma"
                    title="bpma Logo"
                    width={"195px"}
                    height={"65.8px"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>
              Copyright © Mindvision Media Ltd
              <script>document.write(new Date().getFullYear())</script>. All
              Rights Reserved
            </p>
            <div className="text-right right-side">
              <LazyLoadImage
                className="logo-img"
                src={`${gUrl}/assets/images/logos/paypal.svg`}
                alt="paypal logo"
                title="paypal logo"
                width={"91px"}
                height={"26px"}
              />
              <LazyLoadImage
                className="logo-img"
                src={`${gUrl}/assets/images/logos/visa.svg`}
                alt="visa logo"
                title="visa logo"
                width={"77px"}
                height={"20px"}
              />
              <LazyLoadImage
                className="logo-img"
                src={`${gUrl}/assets/images/logos/mastercard.svg`}
                alt="mastercard logo"
                title="mastercard logo"
                width={"130px"}
                height={"22px"}
              />
              <LazyLoadImage
                className="logo-img"
                src={`${gUrl}/assets/images/logos/g-pay.svg`}
                alt="google-pay logo"
                title="google-pay  logo"
                width={"66px"}
                height={"20px"}
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
