import { Link } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { gUrl } from "../../utils/constants";
import { getFileExtension } from "../../utils";

export default function Blogs() {
  const { data: pdata } = useFetch(`general?type=blogs`);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Blogs",
      to: "/blogs",
    },
  ];

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-9">
              <h1 className="cms-page-header mb-2">ALL BLOGS:</h1>
              <div className="row">
                {pdata?.message?.blogs.map((blogs, index) => {
                  const link = "/blog/" + blogs?.link;
                  var background = "/assets/images/blog" + blogs?.image;

                  var fileExt = getFileExtension(background);
                  if (fileExt === "png") {
                    background = `${gUrl}/assets/images/blog/${blogs?.id}/thumb.png`;
                  } else if (fileExt === "jpg") {
                    background = `${gUrl}/assets/images/blog/${blogs?.id}/thumb.jpg`;
                  }
                  return (
                    <div className="col-md-6" key={index}>
                      <div className="news-block">
                        <Link to={link}>
                          <div
                            style={{
                              height: "206px",
                              width: "100%",
                              background: 'url("' + background + '")',
                              backgroundSize: "cover",
                              backgroundPosition: blogs?.ImgPosition,
                            }}
                          ></div>
                        </Link>
                        <div className="position-relative mt-3 mb-3">
                          <Link className="head" to={link}>
                            <h5 className="pb-3">{blogs?.title}</h5>
                          </Link>
                          <p>
                            {blogs.sdescription.length > 150
                              ? `${blogs.sdescription.substring(0, 150)}...`
                              : blogs.sdescription}
                          </p>
                          <Link
                            to={link}
                            className="btn btn-light-green text-center"
                          >
                            Read more…
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-3 blog-list">
              <h2 className="step-title mt-1 mb-2">LATEST BLOGS:</h2>
              {pdata?.message?.blogs.slice(0, 5).map((blogs, index) => {
                return (
                  <a key={index} className="link" href="/blog/' . link . '">
                    <h5>{blogs?.title}</h5>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
