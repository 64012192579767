import { ErrorMessage, Field } from "formik";
import "./index.css";
import FeatherIcon from "feather-icons-react";

export const SelectField = ({
  children,
  name,
  label,
  tooltip,
  tooltipType,
  className,
  ariaLabel,
  onChange,
}) => {
  return (
    <div className={`cselect ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      {tooltip && (
        <span
          className="float-right"
          data-toggle="tooltip"
          data-placement="top"
          title={tooltip}
        >
          <FeatherIcon
            style={{ color: "#C7C6C6", width: "20px", height: "20px" }}
            data-feather={tooltipType}
          />
        </span>
      )}
      <div className="con">
        {onChange ? (
          <Field as="select" name={name} onChange={onChange}>
            {children}
          </Field>
        ) : (
          <Field as="select" name={name} aria-label={ariaLabel}>
            {children}
          </Field>
        )}
      </div>
      <ErrorMessage className="errorFeild" name={name} component="div" />
    </div>
  );
};
