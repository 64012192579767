import { Link } from "react-router-dom";
import BModal from "../modal";
import { Button } from "../button";
import { validationSchemaLogin } from "../../utils/validationSchema";
import { Form, Formik } from "formik";
import { InputField } from "../../components/input-field";
import { apiSrc } from "../../utils/constants";
import { notifyFail, notifySuccess, setCookie } from "../../utils";
import { useState } from "react";

export const LoginModal = ({ show, close, regOpen, tokenUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <BModal show={show} close={close}>
      <Formik
        initialValues={{ search: "" }}
        validationSchema={validationSchemaLogin()}
        onSubmit={(values, { setSubmitting }) => {
          const email = values?.email;
          const password = values?.password;
          const payload = { email, password };

          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(payload),
          };
          setIsLoading(true);
          fetch(`${apiSrc}login`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data?.token) {
                setCookie("token", data?.token, 30);
                notifySuccess("Welcome " + email + "!");
                tokenUpdate && tokenUpdate();
                close();
              } else {
                if (data?.status === 404) notifyFail(data?.message);
                else notifyFail("Something went wrong!");
              }
            })
            .catch((error) => {
              notifyFail("Something went wrong!");
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });

          //navigate(`/gift-finder/${search}/${category}/${price}/${colour}`);
        }}
      >
        <Form className="m-0">
          <div className="modal-body">
            <h3 className="heading-1 mt-2 mb-2"> Login and start shopping!</h3>
            <div className="form-group">
              <InputField
                name={"email"}
                type={"email"}
                label={"Email Address"}
                placeholder="E-mail"
              />
              <InputField
                name={"password"}
                type={"password"}
                label={"Password"}
                placeholder="Password"
              />
            </div>
            <div className="row mt-2">
              <div className="col-12 mb-2">
                <Button
                  id="Loginbtn"
                  type="submit"
                  className="pt-3 pb-3 text-center"
                  title={"LOGIN"}
                  variant={"btn-light-green-full"}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className="text-center">
              <p className="mb-0">
                Don't have an account?{" "}
                <Button
                  onClick={regOpen}
                  type="button"
                  title={"Register here"}
                  variant={"btn-link"}
                />
              </p>

              <p>
                Forgotten your password?{" "}
                <Link
                  title="forgot password"
                  className="btn btn-link"
                  to="/forgot"
                  onClick={close}
                >
                  Reset Password{" "}
                </Link>
              </p>
            </div>
          </div>
        </Form>
      </Formik>
    </BModal>
  );
};
