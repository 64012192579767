import "./index.css";

export const Button = ({
  children,
  id,
  type,
  className,
  variant = "btn-light-green" | "btn-light-orange",
  title,
  iconRight,
  onClick,
  isLoading,
}) => {
  return (
    <button
      onClick={onClick}
      className={`btn ${variant} ${className}`}
      type={type}
      id={id}
    >
      {isLoading ? (
        <div>
          {/* <span>Loading...</span> */}
          <span className="loading-spinner" />
        </div>
      ) : (
        <>
          {title} {iconRight && <i className="icon-angle-right"></i>}
        </>
      )}
    </button>
  );
};
