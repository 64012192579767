import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import Accordion from "react-bootstrap/Accordion";

export default function Faqs() {
  const { data: pdata } = useFetch(`general?type=faqs`);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Faqs",
      to: "/faqs",
    },
  ];

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-12">
                <h1 className="cms-page-header mb-2">
                  Frequently Asked Questions:
                </h1>
                <p className="mb-2">
                  If you don't see the answer to your question below, please
                  call us on <a href="tel:01666826226">01666 826 226 </a>
                  or email:{" "}
                  <a href="mailto:info@mindvision.co.uk">
                    info@mindvision.co.uk
                  </a>
                </p>

                <div className="row">
                  <Accordion defaultActiveKey="0" className="accordion">
                    {pdata?.message?.faqs.map((faqs, index) => {
                      return (
                        <Accordion.Item eventKey={index}>
                          <div className="card">
                            <div className="card-header bg-green">
                              <Accordion.Header>
                                {index + 1 + " ) " + faqs?.title}
                              </Accordion.Header>
                            </div>

                            <Accordion.Body>
                              <div className="card-body">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: faqs?.description,
                                  }}
                                />
                              </div>
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
