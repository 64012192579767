import React, { useState } from "react";
import { apiSrc, gUrl } from "../../utils/constants";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  MySwal,
  authHeader,
  deleteWarning,
  notifyFail,
  notifySuccess,
} from "../../utils";
import SweetAlert2 from "react-sweetalert2";

function SProduct({ item, remfav }) {
  const [swalProps] = useState({});
  var price = parseFloat(item.globalPrice).toFixed(2);
  var title =
    item.title.length > 51 ? item.title.substring(0, 51) + "..." : item.title;

  function removeFav(code) {
    MySwal.fire(deleteWarning).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            request_type: "removeFav",
            code: code,
          }),
        };
        fetch(`${apiSrc}personal_update`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === 200) {
              notifySuccess(data?.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              notifyFail(
                data?.message ? data?.message : "Something went wrong!"
              );
            }
          })
          .catch((error) => {
            notifyFail("Something went wrong!");
            console.error(error);
          })
          .finally(() => {});
      }
    });
  }

  function addToFav(code) {
    fetch(`${apiSrc}personal_update`, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "addToFav",
        code: code,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          notifySuccess(data?.message);
        } else {
          notifyFail(data?.message ? data?.message : "Something went wrong!");
        }
      })
      .catch((error) => {
        notifyFail("Something went wrong!");
        console.error(error);
      })
      .finally(() => {});
  }
  return (
    <div className="product-default inner-icon">
      <figure>
        <Link title={title} to={item.url}>
          <LazyLoadImage
            src={gUrl + item.imgThumb}
            alt={title}
            title={title}
            // onError={(e) => {
            //   e.currentTarget.src = item.image;
            // }}
          />
        </Link>
        {!remfav && (
          <button
            className="btn-transparent btn-icon-heart"
            aria-label={"add to favorite button for " + title}
            onClick={() => addToFav(item.code)}
          >
            <FeatherIcon style={{ color: "#39C4BA" }} icon="heart" />
          </button>
        )}

        {item.rating > 0 && (
          <button
            className="btn-transparent btn-icon-star"
            aria-label="rating button"
          >
            {item.rating}
            <FeatherIcon style={{ color: "#FFC741" }} icon="star" />
          </button>
        )}
      </figure>
      <div className="product-details">
        <h2 className="product-title">
          <Link title={title} to={item.url}>
            {title}
          </Link>
        </h2>
        <div className="price-box">
          <span className="product-price">
            Starting From <span className="grey">£{price}</span>
          </span>
        </div>
        {remfav && (
          <button
            onClick={() => removeFav(item.code)}
            type="button"
            className="btn btn-light-green-border"
          >
            Remove
          </button>
        )}
      </div>
      <SweetAlert2 {...swalProps} />
    </div>
  );
}

export default SProduct;
