import "./App.css";
import Layout from "./layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Layout />
      <ToastContainer
        position="top-center"
        autoClose={1500}
        theme="colored"
        toastClassName="custom-toast"
      />
    </div>
  );
}

export default App;
