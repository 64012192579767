import FeatherIcon from "feather-icons-react";
import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";
import { CenterContentLoader } from "../../utils/loaders";
import "./index.css";

export const OffcanvasMenu = ({ show, onHide }) => {
  const { data: menuData } = useFetch(`general?type=menu`);

  const content = menuData
    ? menuData?.message?.menu.filter((item) => item.title === "Mobile Menu")[0]
        .content
    : [];

  return (
    <>
      <div
        className={`offcanvas ${show ? "show" : ""}`}
        style={{
          right: show ? "0" : "-300px",
        }}
      >
        <div className="offcanvas-header">
          <button className="close" onClick={onHide} aria-label="close">
            <FeatherIcon
              style={{ color: "white", width: "32px", height: "32px" }}
              icon="x-circle"
            />
          </button>
        </div>
        <div className="offcanvas-body">
          {menuData === null ? (
            <div className="container">
              <CenterContentLoader />
            </div>
          ) : (
            <div className="mobile-menu-wrapper">
              <nav className="mobile-nav">
                <ul className="mobile-menu">
                  {JSON.parse(content).map((item) => {
                    return (
                      <li className="" key={item.href}>
                        <Link
                          onClick={onHide}
                          title={item.text}
                          to={item.href}
                          className=""
                        >
                          <i className="empty"></i> {item.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
      {show && <div className="offcanvas-overlay" onClick={onHide} />}
    </>
  );
};
