import FeatherIcon from "feather-icons-react";
import BModal from "../modal";

export const LoginRegModal = ({ show, close, logOpen, regOpen }) => {
  return (
    <BModal show={show} close={close}>
      <h3 className="heading-1 mt-2 mb-2">Checkout options</h3>
      <button
        className="btn btn-light-grey-border-full mb-1"
        onClick={() => {
          logOpen();
          close();
        }}
      >
        Returning Customer
        <div className="float-right">
          <FeatherIcon style={{ color: "#5C5B5E" }} icon="chevron-right" />
        </div>
      </button>
      <button
        className="btn btn-light-grey-border-full mb-1"
        onClick={() => {
          regOpen();
          close();
        }}
      >
        New Customer
        <div className="float-right">
          <FeatherIcon style={{ color: "#5C5B5E" }} icon="chevron-right" />
        </div>
      </button>
    </BModal>
  );
};
