import * as Yup from "yup";

export const validationSchemaGift = () => {
  return Yup.object().shape({
    search: Yup.string().max(50, "Must be 50 or less characters"),
  });
};

export const validationSchemaLogin = () => {
  return Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });
};

export const validationSchemaApprove = () => {
  return Yup.object().shape({
    status: Yup.string().required(),
    userNotes: Yup.string().required(),
  });
};

export const validationSchemaAddress = () => {
  return Yup.object().shape({
    fname: Yup.string().required(),
    lName: Yup.string().required(),
    email: Yup.string().email().required(),
    telephone: Yup.string().required(),
    address1: Yup.string().required(),
    town: Yup.string().required(),
    county: Yup.string().required(),
    postcode: Yup.string().required(),
  });
};

export const validationSchemaSignUp = () => {
  return Yup.object().shape({
    fname: Yup.string().required(),
    lname: Yup.string().required(),
    email: Yup.string().email().required(),
    companyName: Yup.string().required(),
    day: Yup.number()
      .required()
      .integer()
      .typeError("Please enter a valid number"),
    month: Yup.number()
      .required()
      .integer()
      .typeError("Please enter a valid number"),
  });
};

export const validationSchemaContact = () => {
  return Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
  });
};

export const validationSchemaReview = () => {
  return Yup.object().shape({
    star: Yup.string().required(),
    title: Yup.string().required(),
    description: Yup.string().required(),
  });
};

export const validationSchemasOrderSteps = [
  Yup.object().shape({
    deliveryLocation: Yup.string().required("Delivery location is required"),
  }),
  Yup.object().shape({
    colour: Yup.string().required("Colour is required"),
    quantity: Yup.string().required("Quantity is required"),
  }),
  Yup.object().shape({
    branding: Yup.string().required("Branding is required"),
    noOfColours: Yup.string().required("No of colours is required"),
    printPosition: Yup.string().required("Print position is required"),
  }),
];

export const validationSchemasQuoteSteps = [
  Yup.object().shape({
    colour: Yup.string().required("Colour is required"),
    quantity: Yup.string().required("Quantity is required"),
  }),
  Yup.object().shape({
    branding: Yup.string().required("Branding is required"),
    noOfColours: Yup.string().required("No of colours is required"),
    printPosition: Yup.string().required("Print position is required"),
  }),
  Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    telephone: Yup.string().required("Telephone is required"),
    deliveryLocation: Yup.string().required("Delivery location is required"),
    additional: Yup.string(),
  }),
];
