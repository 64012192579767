import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { apiSrc, stepsTexts } from "../../utils/constants";
import { authHeader, errorCode, notifyFail } from "../../utils";
import { Steps } from "../../components/stepper";
import { useState } from "react";
import "./index.css";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../components/input-field";
import { Field, Form, Formik } from "formik";
import { validationSchemaAddress } from "../../utils/validationSchema";
import { NameWithAddress } from "../../components/NameWithAddress";

export default function Shipping() {
  const navigate = useNavigate();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "shipping",
  });

  const [isLoading, setIsLoading] = useState(false);

  const labels = {
    fname: "First Name",
    lname: "Last Name",
    email: "Email",
    tel: "Phone No",
    company: "Company",
    address1: "Address line 1",
    address2: "Address line 2",
    town: "Town",
    county: "County / State / Region",
    postcode: "Postcode / Zipcode",
    country: "Country",
  };

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Checkout",
      to: "/checkout",
    },
    {
      name: "Shipping",
      to: "/shipping",
    },
  ];

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status !== 200 ? (
            errorCode(personalData?.status, personalData?.message)
          ) : (
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Steps active={1} steps={stepsTexts} />
                  </div>
                </div>
                <Formik
                  initialValues={{
                    request_type: "updateShopCart",
                    billingCheckbox: personalData?.message?.cartItem?.checkbox
                      ? JSON.parse(personalData?.message?.cartItem?.checkbox)
                          ?.billingCheckbox
                      : true,
                    deliveryCheckbox: personalData?.message?.cartItem?.checkbox
                      ? JSON.parse(personalData?.message?.cartItem?.checkbox)
                          ?.deliveryCheckbox
                      : true,
                    fname: personalData?.message?.person?.fname,
                    lName: personalData?.message?.person?.lName,
                    email: personalData?.message?.person?.email,
                    telephone: personalData?.message?.person?.telephone,
                    mCompany: personalData?.message?.address?.companyName,
                    address1: personalData?.message?.address?.address1,
                    address2: personalData?.message?.address?.address2,
                    town: personalData?.message?.address?.town,
                    county: personalData?.message?.address?.county,
                    postcode: personalData?.message?.address?.postcode,
                    country: personalData?.message?.address?.country,

                    purchase_no: personalData?.message?.cartItem?.purchase_no,

                    bfirstname: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.firstname,
                    bLastname: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.lastname,
                    bemail: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.email,
                    bphone: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.phone,
                    baddress1: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.address1,
                    baddress2: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.address2,
                    btown: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.town,
                    bcounty: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.county,
                    bpostcode: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.postcode,
                    bcountry: JSON.parse(
                      personalData?.message?.cartItem?.paymentAddress
                    )?.country,

                    sfirstname: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.firstname,
                    sLastname: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.lastname,
                    semail: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.email,
                    sphone: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.phone,
                    saddress1: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.address1,
                    saddress2: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.address2,
                    stown: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.town,
                    scounty: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.county,
                    spostcode: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.postcode,
                    scountry: JSON.parse(
                      personalData?.message?.cartItem?.shippingAddress
                    )?.country,
                  }}
                  validationSchema={validationSchemaAddress()}
                  onSubmit={(values, { setSubmitting }) => {
                    const payload = values;
                    console.log(values);
                    const requestOptions = {
                      method: "POST",
                      headers: authHeader(),
                      body: JSON.stringify(payload),
                    };
                    setIsLoading(true);
                    fetch(`${apiSrc}personal_update`, requestOptions)
                      .then((response) => response.json())
                      .then((data) => {
                        if (data?.status === 200) {
                          navigate(`/payment`);
                        } else {
                          notifyFail(
                            data?.message
                              ? data?.message
                              : "Something went wrong!"
                          );
                        }
                      })
                      .catch((error) => {
                        notifyFail("Something went wrong!");
                        console.error(error);
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                >
                  {(props) => {
                    const { values, setFieldValue } = props;

                    const handleSelectPostcoder = (e) => {
                      const value = e.target.value;
                      if (value !== "") {
                        const address = JSON.parse(value);
                        setFieldValue("mCompany", address?.organisation);
                        setFieldValue("address1", address?.addressline1);
                        setFieldValue("address2", address?.addressline2);
                        setFieldValue("town", address?.posttown);
                        setFieldValue("county", address?.county);
                        setFieldValue("postcode", address?.postcode);
                      }
                    };

                    const handleSelectPostcoderInvoice = (e) => {
                      const value = e.target.value;
                      if (value !== "") {
                        const address = JSON.parse(value);
                        setFieldValue("bCompany", address?.organisation);
                        setFieldValue("baddress1", address?.addressline1);
                        setFieldValue("baddress2", address?.addressline2);
                        setFieldValue("btown", address?.posttown);
                        setFieldValue("bcounty", address?.county);
                        setFieldValue("bpostcode", address?.postcode);
                      }
                    };

                    const handleSelectPostcoderDelivery = (e) => {
                      const value = e.target.value;
                      if (value !== "") {
                        const address = JSON.parse(value);
                        setFieldValue("sCompany", address?.organisation);
                        setFieldValue("saddress1", address?.addressline1);
                        setFieldValue("saddress2", address?.addressline2);
                        setFieldValue("stown", address?.posttown);
                        setFieldValue("scounty", address?.county);
                        setFieldValue("spostcode", address?.postcode);
                      }
                    };

                    return (
                      <Form className="m-0">
                        <NameWithAddress
                          fName={"fname"}
                          fLabel={labels.fname}
                          lName={"lName"}
                          lLabel={labels.lname}
                          eName={"email"}
                          eLabel={labels.email}
                          edisable={true}
                          pName={"telephone"}
                          pLabel={labels.tel}
                          company={"mCompany"}
                          companyLabel={labels.company}
                          address1={"address1"}
                          address1Label={labels.address1}
                          address2={"address2"}
                          address2Label={labels.address2}
                          town={"town"}
                          townLabel={labels.town}
                          county={"county"}
                          countylabel={labels.county}
                          postcode={"postcode"}
                          postcodeLabel={labels.postcode}
                          Country={"Country"}
                          CountryLabel={labels.country}
                          handleSelectPostcoder={handleSelectPostcoder}
                        />

                        <div className="custom-control custom-checkbox mt-1 mb-2">
                          <Field
                            name="billingCheckbox"
                            type="checkbox"
                            className="custom-control-input"
                            id="change-billing-checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="change-billing-checkbox"
                          >
                            Use this as my invoice address.
                          </label>
                        </div>

                        {!values.billingCheckbox && (
                          <div className="col-lg-12 billing">
                            <h3 className="mb-2 grey-bottom">
                              Invoice Address
                            </h3>

                            <NameWithAddress
                              fName={"bfirstname"}
                              fLabel={labels.fname}
                              lName={"bLastname"}
                              lLabel={labels.lname}
                              eName={"bemail"}
                              eLabel={labels.email}
                              pName={"bphone"}
                              pLabel={labels.tel}
                              company={"bCompany"}
                              companyLabel={labels.company}
                              address1={"baddress1"}
                              address1Label={labels.address1}
                              address2={"baddress2"}
                              address2Label={labels.address2}
                              town={"btown"}
                              townLabel={labels.town}
                              county={"bcounty"}
                              countylabel={labels.county}
                              postcode={"bpostcode"}
                              postcodeLabel={labels.postcode}
                              Country={"bcountry"}
                              CountryLabel={labels.country}
                              handleSelectPostcoder={
                                handleSelectPostcoderInvoice
                              }
                            />
                          </div>
                        )}

                        <div className="custom-control custom-checkbox mt-1 mb-2">
                          <Field
                            name="deliveryCheckbox"
                            type="checkbox"
                            className="custom-control-input"
                            id="change-delivery-checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="change-delivery-checkbox"
                          >
                            Use this as my delivery address.
                          </label>
                        </div>

                        {!values.deliveryCheckbox && (
                          <div className="col-lg-12 delivery">
                            <h3 className="mb-2 grey-bottom">
                              Delivery Address
                            </h3>

                            <NameWithAddress
                              fName={"sfirstname"}
                              fLabel={labels.fname}
                              lName={"sLastname"}
                              lLabel={labels.lname}
                              eName={"semail"}
                              eLabel={labels.email}
                              pName={"sphone"}
                              pLabel={labels.tel}
                              company={"sCompany"}
                              companyLabel={labels.company}
                              address1={"saddress1"}
                              address1Label={labels.address1}
                              address2={"saddress2"}
                              address2Label={labels.address2}
                              town={"stown"}
                              townLabel={labels.town}
                              county={"scounty"}
                              countylabel={labels.county}
                              postcode={"spostcode"}
                              postcodeLabel={labels.postcode}
                              Country={"scountry"}
                              CountryLabel={labels.country}
                              handleSelectPostcoder={
                                handleSelectPostcoderDelivery
                              }
                            />
                          </div>
                        )}

                        <InputField
                          name={"purchase_no"}
                          type={"text"}
                          label={
                            "Do you have a Purchase Order No/ Reference No?"
                          }
                          placeholder={"PO number"}
                        />

                        <div className="form-footer">
                          <button
                            className="btn btn-orange"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            <i className="icon-angle-double-left"></i>Back
                          </button>

                          <div className="form-footer-right">
                            <Button
                              className="pt-2 pb-2 text-center"
                              variant={"btn-light-green-full"}
                              type="submit"
                              title={"Next"}
                              isLoading={isLoading}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
