import { Link, useParams, useNavigate } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { dateFormat, errorCode, getSequence, opedPDF } from "../../utils";
import { ProfileMenu } from "../../components/profile-menu";
import { Badge } from "../../components/badge";

export default function Quote() {
  const { quote } = useParams();
  const navigate = useNavigate();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "quote",
    quote: quote,
  });

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "User Profile",
      to: "/personal-details",
    },
    {
      name: "Quotes",
      to: "/quotes",
    },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Quote Details:</h3>
              </div>
              <div className="order-summary mt-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Details: </h3>
                      <address>
                        <b>
                          Quote No: #{personalData?.message?.orderArray?.id}
                          <br />
                          Quote Placed:{" "}
                          {dateFormat(
                            personalData?.message?.orderArray?.order_date
                          )}
                        </b>
                      </address>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="collapse show" id="order-cart-section">
                      <table className="table table-mini-cart">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="product-col">
                              <figure className="product-image-container">
                                <Link to="#" className="product-image">
                                  <img
                                    src={personalData?.message?.product?.image}
                                    alt="product"
                                  />
                                </Link>
                              </figure>
                            </td>
                            <td>
                              <div>
                                <h2 className="product-title">
                                  {personalData?.message?.product?.title}
                                </h2>
                                <span className="product-qty">
                                  Code: {personalData?.message?.product?.code}
                                </span>
                                <span className="product-qty">
                                  Qty:{" "}
                                  {personalData?.message?.orderArray?.quantity}
                                </span>
                              </div>
                            </td>
                            <td>
                              <p>
                                <b>Branding:</b>{" "}
                                {personalData?.message?.orderArray?.branding}
                                <br />
                                <b>Product Colour:</b>{" "}
                                {personalData?.message?.orderArray?.colour}
                                <br />
                                <b>No of Colours:</b>{" "}
                                {personalData?.message?.orderArray?.noOfColours}
                                <br />
                                <b>Print Position:</b>{" "}
                                {
                                  personalData?.message?.orderArray
                                    ?.printPosition
                                }
                                <br />
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-summary mt-1">
                <div className="row">
                  <div className="col-md-12">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Quote History List: </h3>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-res">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Details</th>
                            <th>Total</th>
                            <th>Payment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {personalData?.message?.quotePdfs.map(
                            (quote, index) => {
                              const pdf_details = JSON.parse(quote?.details);
                              if (quote?.email_sent === null) return null;

                              return (
                                <tr key={index}>
                                  <td data-label="#">
                                    {getSequence(
                                      personalData?.message?.orderArray?.id
                                    ) +
                                      "-" +
                                      getSequence(quote?.version)}
                                  </td>
                                  <td data-label="Product">
                                    {pdf_details?.product_code && (
                                      <>
                                        <b>Code:</b> {pdf_details?.product_code}{" "}
                                        <br />
                                      </>
                                    )}
                                    {pdf_details?.pquantity && (
                                      <>
                                        <b>Qty:</b> {pdf_details?.pquantity}{" "}
                                        <br />
                                      </>
                                    )}
                                  </td>
                                  <td data-label="Details">
                                    {pdf_details?.supplier_id ? (
                                      <>
                                        <b>Branding:</b> {pdf_details?.branding}{" "}
                                        <br />
                                        <b>Product Colour:</b>{" "}
                                        {pdf_details?.colour} <br />
                                        <b>Print Colours:</b>{" "}
                                        {pdf_details?.noOfColours} <br />
                                        <b>Print Position:</b>{" "}
                                        {pdf_details?.printPosition} <br />{" "}
                                      </>
                                    ) : (
                                      <>
                                        Combined Quotes{" "}
                                        {pdf_details?.selected_quote_versions}
                                      </>
                                    )}
                                  </td>
                                  <td data-label="Total">
                                    {pdf_details?.supplier_id && (
                                      <>£{pdf_details?.totalCostIncVat} </>
                                    )}
                                  </td>
                                  <td data-label="Payment">
                                    {quote?.paidby && (
                                      <Badge name={`${quote?.paidby}`} />
                                    )}
                                  </td>
                                  <td data-label="Action">
                                    <button
                                      onClick={() => opedPDF(quote?.pdf)}
                                      className="btn btn-sm btn-light-green mb-1"
                                    >
                                      <i className="fa fa-eye"></i> VIEW PDF NOW
                                    </button>
                                    <br />
                                    {pdf_details?.supplier_id &&
                                      !quote?.paidby && (
                                        <Link
                                          to={`/quote/${personalData?.message?.orderArray?.id}/${quote?.id}`}
                                          className="btn btn-sm btn-orange"
                                        >
                                          <i className="fas fa-credit-card"></i>{" "}
                                          Place Order
                                        </Link>
                                      )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-left">
                <button
                  className="btn btn-orange mb-1"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon-angle-double-left"></i>Back
                </button>
              </p>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
