import { useNavigate } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { ProfileMenu } from "../../components/profile-menu";
import { InputField } from "../../components/input-field";
import { Form, Formik } from "formik";
import { CenterContentLoader } from "../../utils/loaders";
import { authHeader, errorCode, notifyFail, notifySuccess } from "../../utils";
import { apiSrc } from "../../utils/constants";
import { useState } from "react";
import { Button } from "../../components/button";

export default function PasswordDetails() {
  const navigate = useNavigate();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "updateUser",
  });
  const [isLoading, setIsLoading] = useState(false);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: "Change Password", to: "/change-password" },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Change Password:</h3>
              </div>
              <Formik
                initialValues={{
                  request_type: "updateUserPassword",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const payload = values;

                  const requestOptions = {
                    method: "POST",
                    headers: authHeader(),
                    body: JSON.stringify(payload),
                  };
                  setIsLoading(true);
                  fetch(`${apiSrc}personal_update`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                      if (data?.status === 200) {
                        notifySuccess(data?.message);
                      } else {
                        notifyFail(
                          data?.message
                            ? data?.message
                            : "Something went wrong!"
                        );
                      }
                    })
                    .catch((error) => {
                      notifyFail("Something went wrong!");
                      console.error(error);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              >
                {(props) => {
                  return (
                    <Form className="m-0">
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            name={"password"}
                            type={"password"}
                            label={"New Password:"}
                            placeholder="New Password"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            name={"cpassword"}
                            type={"password"}
                            label={"Confirm Password:"}
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>

                      <div className="form-footer">
                        <button
                          className="btn btn-orange"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          <i className="icon-angle-double-left"></i>Back
                        </button>

                        <div className="form-footer-right">
                          <Button
                            className="pt-2 pb-2 text-center"
                            variant={"btn-light-green-full"}
                            type="submit"
                            title={"Save"}
                            isLoading={isLoading}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
