import "./index.css";
import { errorCode } from "../../utils";

function Error() {
  return (
    <div id="notfound">
      {errorCode("404", "Oops, The Page you are looking for can't be found!")}
    </div>
  );
}

export default Error;
