import useFetch from "../../hooks/useFetch";
import "./index.css";

export const Badge = ({ name }) => {
  const { data } = useFetch(`general?type=badge&name=${name}`);
  var badge = data?.message?.badge;

  if (badge) {
    return (
      <span
        className="badge"
        style={{ color: badge?.textColour, backgroundColor: badge?.colour }}
      >
        {name}
      </span>
    );
  } else {
    return <span className="badge badge-secondary"> {name} </span>;
  }
};
export function badgeGeneral(status) {
  if (status === "Approved" || status === 1) {
    return <span className="badge badge-success"> {status} </span>;
  } else if (status === "Disapproved") {
    return <span className="badge badge-danger"> {status} </span>;
  } else {
    return <span className="badge badge-dark"> {status} </span>;
  }
}
