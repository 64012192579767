import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import { SelectField } from "../../components/select-field";
import { Form, Formik } from "formik";
import { validationSchemaGift } from "../../utils/validationSchema";
import { useRef, useState } from "react";
import { InputField } from "../../components/input-field";
import { CenterContentLoader } from "../../utils/loaders";
import SProduct from "../../components/SProduct";

function ShowingTile({ giftProductsData }) {
  var showing = "";
  if (giftProductsData && giftProductsData.calc_page != null) {
    var max =
      parseInt(giftProductsData.calc_page) +
      parseInt(giftProductsData.num_results_on_page);
    if (max > parseInt(giftProductsData.total_pages))
      max = parseInt(giftProductsData.total_pages);
    showing = parseInt(giftProductsData.calc_page) + 1 + "-" + max;
  }

  return (
    <label>
      {showing &&
        `Showing ${showing} of ${giftProductsData.total_pages} results`}
    </label>
  );
}

function GiftFinder() {
  const navigate = useNavigate();
  const scrollto = useRef(null);
  const { search, category, price, color } = useParams();

  const [formData, setFormData] = useState({
    searchitem: search === "-" ? "" : search,
    categoryitem: category === "-" ? "" : category,
    priceitem: price === "-" ? "" : price,
    coloritem: color === "-" ? "" : color,
    page: 1,
    noOfItemsView: 20,
  });

  const { data: giftData } = useFetch("gift-finder");
  const { data: giftProductsData } = useFetch(
    `gift-finder?search=${formData.searchitem}&category=${formData.categoryitem}&price=${formData.priceitem}&color=${formData.coloritem}&view=${formData.noOfItemsView}&page=${formData.page}`
  );

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: "active", to: "Gift Finder Search" },
  ];

  function changePage(num) {
    setFormData({
      ...formData,
      page: num,
    });
    scrollto.current.scrollIntoView({ behavior: "smooth" });
  }

  function changeView(num) {
    setFormData({
      ...formData,
      noOfItemsView: num,
    });
  }

  return (
    <div className="container">
      <div ref={scrollto}>
        <Breadcrumb array={BreadcrumbData} />
        {giftData === null ? (
          <div className="container mt-5 mb-5">
            <CenterContentLoader />
          </div>
        ) : (
          <div className="col-md-12">
            <Formik
              initialValues={{ search: "" }}
              validationSchema={validationSchemaGift()}
              onSubmit={(values, { setSubmitting }) => {
                const search = values?.search ? values?.search : "";
                const category = values?.category ? values?.category : "";
                const price = values?.price ? values?.price : "";
                const colour = values?.colour ? values?.colour : "";

                navigate(
                  `/gift-finder/${search}/${category}/${price}/${colour}`
                );
              }}
            >
              <Form className="m-0">
                <div className="row gift-finder">
                  <div className="col-md-3">
                    <div className="outer-search">
                      <InputField
                        name={"search"}
                        type={"text"}
                        placeholder="By keyword"
                        value={
                          formData.searchitem !== "-" ? formData.searchitem : ""
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            searchitem: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <SelectField
                      name={"category"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          categoryitem: e.target.value,
                        });
                      }}
                    >
                      <option value="">Any category</option>
                      {giftData?.categories &&
                        giftData?.categories.map((item) => {
                          return (
                            <option
                              key={item}
                              value={item}
                              selected={
                                formData.categoryitem === item ? "true" : ""
                              }
                            >
                              {item}
                            </option>
                          );
                        })}
                    </SelectField>
                  </div>
                  <div className="col-md-3">
                    <SelectField
                      name={"price"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          priceitem: e.target.value,
                        });
                      }}
                    >
                      <option value="">Any price</option>
                      <option
                        value="0 AND 1"
                        selected={
                          formData.priceitem === "0 AND 1" ? "true" : ""
                        }
                      >
                        £0 - £1
                      </option>
                      <option
                        value="1 AND 2"
                        selected={
                          formData.priceitem === "1 AND 2" ? "true" : ""
                        }
                      >
                        £1 - £2
                      </option>
                      <option
                        value="2 AND 5"
                        selected={
                          formData.priceitem === "2 AND 5" ? "true" : ""
                        }
                      >
                        £2 - £5
                      </option>
                      <option
                        value="5 AND 10"
                        selected={
                          formData.priceitem === "5 AND 10" ? "true" : ""
                        }
                      >
                        £5 - £10
                      </option>
                      <option
                        value="10 AND 20"
                        selected={
                          formData.priceitem === "10 AND 20" ? "true" : ""
                        }
                      >
                        £10 - £20
                      </option>
                      <option
                        value="20 AND 30"
                        selected={
                          formData.priceitem === "20 AND 30" ? "true" : ""
                        }
                      >
                        £20 - £30
                      </option>
                      <option
                        value="30 AND 40"
                        selected={
                          formData.priceitem === "30 AND 40" ? "true" : ""
                        }
                      >
                        £30 - £40
                      </option>
                      <option
                        value="40 AND 50"
                        selected={
                          formData.priceitem === "40 AND 50" ? "true" : ""
                        }
                      >
                        £40 - £50
                      </option>
                      <option
                        value="50 AND 100"
                        selected={
                          formData.priceitem === "50 AND 100" ? "true" : ""
                        }
                      >
                        £50 - £100
                      </option>
                      <option
                        value="100 AND 1000000"
                        selected={
                          formData.priceitem === "100 AND 1000000" ? "true" : ""
                        }
                      >
                        £100+
                      </option>
                    </SelectField>
                  </div>
                  <div className="col-md-3">
                    <SelectField
                      name={"colour"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          coloritem: e.target.value,
                        });
                      }}
                    >
                      <option value="">Any colour</option>
                      {giftData?.colors &&
                        giftData?.colors.map((item) => {
                          return (
                            <option
                              key={item}
                              value={item}
                              selected={
                                formData.coloritem === item ? "true" : ""
                              }
                            >
                              {item}
                            </option>
                          );
                        })}
                    </SelectField>
                  </div>
                  {/* <div className="col-md-2">
                    <button className="btn btn-dark-green-full p-2 mb-2">
                      Filter
                    </button>
                  </div> */}
                </div>
              </Form>
            </Formik>
            {giftProductsData && (
              <div className="row mb-5 mt-2">
                <div className="col-md-12 col-sm-12">
                  <nav className="toolbox">
                    {/* <div className="toolbox-left">
                      <div className="toolbox-item toolbox-sort">
                        <button
                          href="#"
                          className="sorter-btn"
                          title="Set Ascending Direction"
                        >
                          Set Ascending Direction
                        </button>
                      </div>
                    </div> */}
                    <div className="toolbox-item toolbox-show">
                      <ShowingTile giftProductsData={giftProductsData} />
                    </div>
                    <div className="layout-modes">
                      <div className="select-custom">
                        <select
                          name="view"
                          className="form-control select-light "
                          onChange={(e) => changeView(e.target.value)}
                        >
                          <option
                            value="10"
                            selected={
                              giftProductsData.num_results_on_page === 10 &&
                              "selected"
                            }
                          >
                            View 10
                          </option>
                          <option
                            value="20"
                            selected={
                              giftProductsData.num_results_on_page === 20 &&
                              "selected"
                            }
                          >
                            View 20
                          </option>
                          <option
                            value="30"
                            selected={
                              giftProductsData.num_results_on_page === 30 &&
                              "selected"
                            }
                          >
                            View 30
                          </option>
                          <option
                            value="40"
                            selected={
                              giftProductsData.num_results_on_page === 40 &&
                              "selected"
                            }
                          >
                            View 40
                          </option>
                          <option
                            value="50"
                            selected={
                              giftProductsData.num_results_on_page === 50 &&
                              "selected"
                            }
                          >
                            View 50
                          </option>
                        </select>
                      </div>
                    </div>
                  </nav>

                  <div className="row mb-5" id="response">
                    {giftProductsData?.products &&
                    giftProductsData?.products.length > 0 ? (
                      giftProductsData?.products.map((item) => {
                        return (
                          <div className="col-lg-3 col-md-4 col-6">
                            <SProduct item={item} key={item} />{" "}
                          </div>
                        );
                      })
                    ) : (
                      <div className="heading-container-1 mt-1 ">
                        <h2 className="heading-1">
                          Sorry, your search returned no results
                        </h2>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      {Math.ceil(
                        parseInt(giftProductsData.total_pages) /
                          parseInt(giftProductsData.num_results_on_page)
                      ) > 0 && (
                        <ul className="pagination-new">
                          {giftProductsData.page > 1 && (
                            <li className="prev">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    parseInt(giftProductsData.page) - 1
                                  )
                                }
                              >
                                Prev
                              </button>
                            </li>
                          )}
                          {giftProductsData.page > 3 && (
                            <li className="start">
                              <button
                                type="button"
                                onClick={() => changePage(1)}
                              >
                                1
                              </button>
                            </li>
                          )}
                          {giftProductsData.page > 3 && (
                            <li className="dots">...</li>
                          )}
                          {giftProductsData.page - 2 > 0 && (
                            <li className="page">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    parseInt(giftProductsData.page) - 2
                                  )
                                }
                              >
                                {giftProductsData.page - 2}
                              </button>
                            </li>
                          )}
                          {giftProductsData.page - 1 > 0 && (
                            <li className="page">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    parseInt(giftProductsData.page) - 1
                                  )
                                }
                              >
                                {giftProductsData.page - 1}
                              </button>
                            </li>
                          )}
                          <li className="currentpage">
                            <button
                              type="button"
                              onClick={() => changePage(giftProductsData.page)}
                            >
                              {giftProductsData.page}
                            </button>
                          </li>
                          {giftProductsData.page + 1 <
                            Math.ceil(
                              parseInt(giftProductsData.total_pages) /
                                parseInt(giftProductsData.num_results_on_page)
                            ) +
                              1 && (
                            <li className="page">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    parseInt(giftProductsData.page) + 1
                                  )
                                }
                              >
                                {parseInt(giftProductsData.page) + 1}
                              </button>
                            </li>
                          )}
                          {giftProductsData.page + 2 <
                            Math.ceil(
                              parseInt(giftProductsData.total_pages) /
                                parseInt(giftProductsData.num_results_on_page)
                            ) +
                              1 && (
                            <li className="page">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    parseInt(giftProductsData.page) + 2
                                  )
                                }
                              >
                                {parseInt(giftProductsData.page) + 2}
                              </button>
                            </li>
                          )}
                          {giftProductsData.page <
                            Math.ceil(
                              parseInt(giftProductsData.total_pages) /
                                parseInt(giftProductsData.num_results_on_page)
                            ) -
                              2 && <li className="dots">...</li>}
                          {giftProductsData.page <
                            Math.ceil(
                              parseInt(giftProductsData.total_pages) /
                                parseInt(giftProductsData.num_results_on_page)
                            ) -
                              2 && (
                            <li className="end">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    Math.ceil(
                                      parseInt(giftProductsData.total_pages) /
                                        parseInt(
                                          giftProductsData.num_results_on_page
                                        )
                                    )
                                  )
                                }
                              >
                                {Math.ceil(
                                  parseInt(giftProductsData.total_pages) /
                                    parseInt(
                                      giftProductsData.num_results_on_page
                                    )
                                )}
                              </button>
                            </li>
                          )}
                          {giftProductsData.page <
                            Math.ceil(
                              parseInt(giftProductsData.total_pages) /
                                parseInt(giftProductsData.num_results_on_page)
                            ) && (
                            <li className="next">
                              <button
                                type="button"
                                onClick={() =>
                                  changePage(
                                    parseInt(giftProductsData.page) + 1
                                  )
                                }
                              >
                                Next
                              </button>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GiftFinder;
