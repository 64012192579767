import "./index.css";
import useFetch from "../../hooks/useFetch";
import { ProfileMenu } from "../../components/profile-menu";

import { CenterContentLoader } from "../../utils/loaders";
import {
  MySwal,
  authHeader,
  deleteWarning,
  errorCode,
  notifyFail,
  notifySuccess,
} from "../../utils";

import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { AddressModal } from "../../components/modals/address";
import { apiSrc } from "../../utils/constants";
import SweetAlert2 from "react-sweetalert2";

export default function DeliveryAddress() {
  const [addresshow, setAddresshow] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [swalProps, setSwalProps] = useState({});

  const handleClose = () => {
    setSwalProps({
      show: false,
    });
    setEditAddress(false);
    setAddresshow(false);
  };
  const addOpen = () => setAddresshow(true);

  const editAddressOpen = (data) => {
    setEditAddress(data);
    setAddresshow(true);
  };

  const delAddress = (item) => {
    MySwal.fire(deleteWarning).then((result) => {
      if (result.isConfirmed) {
        const id = item?.addressID;
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            request_type: "rmAdd",
            id: id,
          }),
        };
        fetch(`${apiSrc}personal_update`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === 200) {
              notifySuccess(data?.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              notifyFail(
                data?.message ? data?.message : "Something went wrong!"
              );
            }
          })
          .catch((error) => {
            notifyFail("Something went wrong!");
            console.error(error);
          })
          .finally(() => {});
      }
    });
  };

  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "delivery",
  });

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: "Delivery Address", to: "/delivery-details" },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>
                  Delivery address:
                  <button className="btn position-absolute" onClick={addOpen}>
                    <FeatherIcon
                      style={{ color: "#39C4BA" }}
                      icon="plus-circle"
                    />{" "}
                    ADD NEW ADDRESS
                  </button>
                </h3>
              </div>
              <div className="multi-address-container">
                <div className="row">
                  {personalData?.message?.addresses.map((item, index) => {
                    const person = JSON.parse(item?.personDetails);
                    return (
                      <div className="col-md-4" key={index}>
                        <div className="cover">
                          <div className="name">
                            <h3 className="mb-0">
                              {person?.firstname + " " + person?.lastname}
                            </h3>
                            <div className="btns">
                              <button
                                className="btn-transparent"
                                onClick={() => editAddressOpen(item)}
                              >
                                <FeatherIcon
                                  style={{ color: "#39C4BA" }}
                                  icon="edit-2"
                                />
                              </button>
                              <button
                                className="btn-transparent"
                                onClick={() => delAddress(item)}
                              >
                                <FeatherIcon
                                  style={{ color: "#39C4BA" }}
                                  icon="trash"
                                />
                              </button>
                            </div>
                          </div>

                          <p>
                            {item?.companyName && (
                              <>
                                {item?.companyName}
                                <br />
                              </>
                            )}
                            {item?.address1 && (
                              <>
                                {item?.address1}
                                <br />
                              </>
                            )}
                            {item?.address2 && (
                              <>
                                {item?.address2}
                                <br />
                              </>
                            )}
                            {item?.town && (
                              <>
                                {item?.town} {item?.county}
                                <br />
                              </>
                            )}
                            {item?.postcode && (
                              <>
                                {item?.postcode}
                                <br />
                              </>
                            )}
                            {item?.country === "GB" && (
                              <>
                                United Kingdom
                                <br />
                              </>
                            )}
                            Tel: {person?.phone}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
      <AddressModal
        show={addresshow}
        close={handleClose}
        regOpen={addOpen}
        pid={personalData?.message?.cpd}
        editAddress={editAddress}
      />
      <SweetAlert2 {...swalProps} />
    </div>
  );
}
