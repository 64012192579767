import { ProfileMenu } from "../../components/profile-menu";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { errorCode, results } from "../../utils";
import SProduct from "../../components/SProduct";

export const Favourites = () => {
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "fav",
  });

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: "Favourites", to: "/favourites" },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Favourites:</h3>
              </div>
              <div className="multi-address-container">
                <div className="row">
                  {personalData?.message?.products.map((item) => {
                    return (
                      <div className="col-md-4" key={item.url}>
                        <SProduct key={item.url} item={item} remfav={true} />
                      </div>
                    );
                  })}
                  {personalData?.message?.products?.length === 0 && (
                    <>{results("No results available")}</>
                  )}
                </div>
              </div>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
};
