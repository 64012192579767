import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";
import Error from "./pages/error";
import Header from "./components/header";
import Home from "./pages/home";
import Footer from "./components/footer";
import ProductDetail from "./pages/product-detail";
import Category from "./pages/category";
import SubCategory from "./pages/sub-category";
import GiftFinder from "./pages/gift-finder";
import PersonalDetails from "./pages/personal-details";
import { Logout } from "./pages/logout/logout";
import InvoiceAddress from "./pages/invoice-address";
import PasswordDetails from "./pages/password";
import DeliveryAddress from "./pages/delivery-address";
import { Favourites } from "./pages/favourites";
import Page from "./pages/page";
import Blogs from "./pages/blogs";
import Blog from "./pages/blog";
import Faqs from "./pages/faqs";
import Quotes from "./pages/quotes";
import Quote from "./pages/quote";
import Orders from "./pages/orders";
import Order from "./pages/order";
import Checkout from "./pages/checkout";
import Shipping from "./pages/shipping";
import Payment from "./pages/payment";
import OrderComplete from "./pages/order-complete";
import OrderFailed from "./pages/order-failed";
import ContactUs from "./pages/contact";
import QuotePayment from "./pages/quote-payment";
import Proofs from "./pages/proofs";
import Categories from "./pages/categories";
import Promotions from "./pages/promotions";

const Layout = () => {
  const Structure = () => (
    <>
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </>
  );
  // initialize a browser router
  const router = createBrowserRouter([
    {
      element: <Structure />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/page/:page",
          element: <Page />,
        },
        {
          path: "/blogs",
          element: <Blogs />,
        },
        {
          path: "/blog/:blog",
          element: <Blog />,
        },
        {
          path: "/faqs",
          element: <Faqs />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },
        {
          path: "/promotional-merchandise/:category/:pcode/:pname",
          element: <ProductDetail />,
        },
        {
          path: "/categories",
          element: <Categories />,
        },
        {
          path: "/category/:mcategory",
          element: <Category />,
        },
        {
          path: "/category/:mcategory/:sub",
          element: <SubCategory />,
        },
        {
          path: "/quotes",
          element: <Quotes />,
        },
        {
          path: "/quote/:quote",
          element: <Quote />,
        },
        {
          path: "/quote/:quote/:subId",
          element: <QuotePayment />,
        },
        {
          path: "/orders",
          element: <Orders />,
        },
        {
          path: "/checkout",
          element: <Checkout />,
        },
        {
          path: "/shipping",
          element: <Shipping />,
        },
        {
          path: "/payment",
          element: <Payment />,
        },
        {
          path: "/order-complete",
          element: <OrderComplete />,
        },
        {
          path: "/order-complete/:oid",
          element: <OrderComplete />,
        },
        {
          path: "/order-failed",
          element: <OrderFailed />,
        },
        {
          path: "/order/:order",
          element: <Order />,
        },
        {
          path: "/order/:order/proofs",
          element: <Proofs />,
        },
        {
          path: "/personal-details",
          element: <PersonalDetails />,
        },
        {
          path: "/billing-details",
          element: <InvoiceAddress />,
        },
        {
          path: "/change-password",
          element: <PasswordDetails />,
        },
        {
          path: "/delivery-details",
          element: <DeliveryAddress />,
        },
        {
          path: "/favourites",
          element: <Favourites />,
        },
        {
          path: "/promotions/:id",
          element: <Promotions />,
        },
        {
          path: "/gift-finder/:search?/:category?/:price?/:color?",
          element: <GiftFinder />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
        {
          path: "/admin/*",
          loader: () => null, // pass through to server
        },
        {
          path: "*",
          element: <Error />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default Layout;
