import "./index.css";
import { Stepper } from "react-form-stepper";

export const Steps = ({
  active,
  steps,
  completedBgColor = "#925f96",
  activeBgColor = "#26ccca",
}) => {
  return (
    <Stepper
      styleConfig={{
        completedBgColor,
        activeBgColor,
        size: "4em",
        labelFontSize: "2em",
      }}
      steps={steps}
      activeStep={active}
    />
  );
};
