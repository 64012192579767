import { useState } from "react";
import { InputField } from "../input-field";
import { SelectField } from "../select-field";
import "./index.css";
import { fetchAddresses } from "../../utils";

export const NameWithAddress = ({
  fLabel,
  fName,
  fdisable,
  lLabel,
  lName,
  ldisable,
  eLabel,
  eName,
  edisable,
  pLabel,
  pName,
  pdisable,
  companyLabel,
  company,
  address1Label,
  address1,
  address2Label,
  address2,
  townLabel,
  town,
  countylabel,
  county,
  postcodeLabel,
  postcode,
  CountryLabel,
  Country,
  handleSelectPostcoder,
}) => {
  const [addresses, setAddresses] = useState([]);
  const [timer, setTimer] = useState(null);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        handleInputChangeAfterDelay(value);
        setTimer(null); // reset the timer
      }, 1000)
    );
  };

  const handleInputChangeAfterDelay = (value) => {
    fetchAddresses(value)
      .then((data) => {
        setAddresses(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <InputField
            name={fName}
            type={"text"}
            label={fLabel}
            placeholder={fLabel}
            disabled={fdisable}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={lName}
            type={"text"}
            label={lLabel}
            placeholder={lLabel}
            disabled={ldisable}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={eName}
            className={"form-control"}
            type={"email"}
            label={eLabel}
            placeholder={eLabel}
            disabled={edisable}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={pName}
            type={"number"}
            label={pLabel}
            placeholder={pLabel}
            disabled={pdisable}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="small-header header-bottom-grey mb-2">
            <h3>Address Details:</h3>
          </div>
        </div>
        <div className="col-md-12">
          <div className="" id="address_finder">
            <div className="form-group">
              <label
                htmlFor="postcoder_autocomplete"
                id="postcoder_autocomplete_label"
              >
                Type in your address or postcode :
              </label>
              <input
                id="postcoder_autocomplete"
                type="text"
                className="form-control select-light"
                onChange={handleSearch}
              />
            </div>
            {addresses?.length > 0 && (
              <select
                className="cselect cselectstyle"
                onChange={handleSelectPostcoder}
              >
                <option value="">Select an address</option>
                {addresses.map((address, index) => {
                  return (
                    <option key={index} value={JSON.stringify(address)}>
                      {address.summaryline}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
          <div className="mb-2 header-bottom-grey"></div>
        </div>
        <div className="col-md-12">
          <InputField
            name={company}
            type={"text"}
            label={companyLabel}
            placeholder={companyLabel}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={address1}
            type={"text"}
            label={address1Label}
            placeholder={address1Label}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={address2}
            type={"text"}
            label={address2Label}
            placeholder={address2Label}
          />
        </div>

        <div className="col-md-6">
          <InputField
            name={town}
            type={"text"}
            label={townLabel}
            placeholder={townLabel}
          />
        </div>

        <div className="col-md-6">
          <InputField
            name={county}
            type={"text"}
            label={countylabel}
            placeholder={countylabel}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={postcode}
            type={"text"}
            label={postcodeLabel}
            placeholder={postcodeLabel}
          />
        </div>

        <div className="col-md-6  ">
          <SelectField label={CountryLabel} name={Country}>
            <option value="GB">United Kingdom</option>
          </SelectField>
        </div>
      </div>
    </>
  );
};
