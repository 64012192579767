import { useState } from "react";
import { authHeader, notifyFail, notifySuccess } from "../../utils";
import { validationSchemaApprove } from "../../utils/validationSchema";
import BModal from "../modal";
import { Form, Formik } from "formik";
import { apiSrc } from "../../utils/constants";
import { Button } from "../button";
import { TextArea } from "../input-field/textArea";
import { SelectField } from "../select-field";

export const ApproveModal = ({ show, close, oid, proofId }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <BModal show={show} close={close}>
      <Formik
        initialValues={{}}
        validationSchema={validationSchemaApprove()}
        onSubmit={(values, { setSubmitting }) => {
          const status = values?.status;
          const userNotes = values?.userNotes;
          console.log(proofId);
          const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
              request_type: "changeProofStatus",
              oid,
              status,
              userNotes,
              proofId,
            }),
          };
          setIsLoading(true);
          fetch(`${apiSrc}personal_update`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data?.status === 200) {
                notifySuccess(data?.message);
                close();
              } else {
                notifyFail(
                  data?.message ? data?.message : "Something went wrong!"
                );
              }
            })
            .catch((error) => {
              notifyFail("Something went wrong!");
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        <Form className="m-0">
          <h3 className="heading-1 mt-2 mb-2">Change Status for Ref #{oid}!</h3>
          <div className="form-group">
            <SelectField label={"Status"} name={"status"}>
              <option value="">Choose one</option>
              <option value="Approved">Approve</option>
              <option value="Disapproved">Disapprove</option>
            </SelectField>
            <TextArea
              label={"Notes(optional):"}
              className="form-control"
              name="userNotes"
            />
          </div>
          <div className="row mt-2">
            <div className="col-12 mb-2">
              <Button
                type="submit"
                className="pt-3 pb-3 text-center"
                title={"Save"}
                variant={"btn-light-green-full"}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </BModal>
  );
};
