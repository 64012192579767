import React from "react";
import { Modal } from "react-bootstrap";
import { gUrl } from "../../utils/constants";

function BModal({ show, close, children }) {
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <img
          src={`${gUrl}/assets/images/logos/mindvision-logo-white-text.png`}
          alt="Mindvision media ltd logo"
          title="Mindvision media ltd logo"
          style={{ width: "291px", height: "auto" }}
        />
        <button
          style={{ color: "white" }}
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={close}
        >
          ×
        </button>
        {/* <Modal.Title>Modal title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default BModal;
