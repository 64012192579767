import { useState, useEffect } from "react";
import { apiSrc } from "../utils/constants";
import { authHeader } from "../utils";

// List of URLs to cache
const cacheableUrls = [
  "general?type=menu",
  "general?type=slug",
  "general?type=categories",
  "general?type=banners",
  "general?type=home-products",
];

// Cache expiration time (24 hours in milliseconds)
const CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1000;

const useFetch = (url, method, body) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      setLoading(true);

      // Check if the URL is cacheable
      if (cacheableUrls.includes(url)) {
        const cachedData = localStorage.getItem(url);

        if (cachedData) {
          const { data: cachedResult, timestamp } = JSON.parse(cachedData);

          // Check if the cached data is still valid (within 24 hours)
          if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
            setData(cachedResult);
            setLoading(false);
            return;
          }
        }
      }

      const config = body
        ? {
            method: method,
            headers: authHeader(),
            body: JSON.stringify(body),
          }
        : {
            method: method,
            headers: authHeader(),
          };

      try {
        const response = !method
          ? await fetch(`${apiSrc}${url}`)
          : await fetch(`${apiSrc}${url}`, config);
        if (!response.ok) throw new Error(response.statusText);

        const result = await response.json();
        setData(result);

        // Cache the result in localStorage if the URL is cacheable
        if (cacheableUrls.includes(url)) {
          const cacheObject = {
            data: result,
            timestamp: Date.now(), // Save the timestamp
          };
          localStorage.setItem(url, JSON.stringify(cacheObject));
        }

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]); // Only trigger when `url` changes

  return { data, loading, error };
};

export default useFetch;
