import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  asyncCall,
  authHeader,
  formatNumber,
  getCookie,
  notifyFail,
  scrollHandle,
} from "../../utils";
import { apiSrc } from "../../utils/constants";
import { Stepper } from "react-form-stepper";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { validationSchemasOrderSteps } from "../../utils/validationSchema";
import { useNavigate } from "react-router-dom";

export const OrderMultiStep = ({ code, name, data, scroll, toggleLGShow }) => {
  const pricingRef = useRef(null);
  const navigate = useNavigate();
  const [openPricingContainer, setOpenPricingContainer] = useState(false);

  const openPC = () => {
    setOpenPricingContainer(true);
    scrollHandle(pricingRef);
  };

  const closePC = () => {
    setOpenPricingContainer(false);
  };

  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    colour: "",
    quantity: "",
    branding: "",
    noOfColours: "",
    printPosition: "",
    deliveryLocation: "UK (Exc. Highlands & Islands)",
  });

  const [pricevalues, setPriceValues] = useState({
    carriage_cost: 0,
    color_cost: 0,
    origination: 0,
    totalCostExVat: 0,
    vat: 0,
    totalCostIncVat: 0,
  });
  const [errors, setErrors] = useState({});
  const [brandingOptions, setBrandingOptions] = useState([]);
  const [noOfColoursOptions, setNoOfColoursOptions] = useState([]);
  const [printPositionOptions, setPrintPositionOptions] = useState([]);

  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "updateUser",
  });

  const validationSchemas = validationSchemasOrderSteps;

  const getBranding = useCallback(
    async (quantity) => {
      const response = await asyncCall(apiSrc + "generalUpdate", "POST", {
        request_type: "getBranding",
        quantity: quantity,
        product: data?.quantity_breaks,
      });

      if (typeof response?.message?.brandingList === "string") {
        setBrandingOptions([`${response?.message?.brandingList}`] || []);
      } else {
        setBrandingOptions(response?.message?.brandingList || []);
      }
    },
    [data, setBrandingOptions]
  );

  const getColorsPosition = useCallback(async () => {
    const response = await asyncCall(apiSrc + "generalUpdate", "POST", {
      request_type: "getColorsPosition",
      quantity: values.quantity,
      branding: values.branding,
      product: data?.quantity_breaks,
    });

    if (typeof response?.message?.noOfColours === "string") {
      setNoOfColoursOptions([`${response?.message?.noOfColours}`] || []);
    } else {
      setNoOfColoursOptions(response?.message?.noOfColours || []);
    }

    if (typeof response?.message?.printPosition === "string") {
      setPrintPositionOptions([`${response?.message?.printPosition}`] || []);
    } else {
      setPrintPositionOptions(response?.message?.printPosition || []);
    }
  }, [values.quantity, values.branding, data?.quantity_breaks]);

  useEffect(() => {
    if (values.quantity) getBranding(values.quantity);
  }, [values.quantity, getBranding]);

  useEffect(() => {
    if (values.branding) {
      getColorsPosition();
      if (personalData?.status !== 423) {
        setValues((prevValues) => ({
          ...prevValues,
          companyName: personalData?.message?.address?.companyName,
          firstName: personalData?.message?.person?.fname,
          lastName: personalData?.message?.person?.lName,
          email: personalData?.message?.person?.email,
          telephone: personalData?.message?.person?.telephone,
        }));
      }
      closePC();
    }
  }, [values.branding, personalData, getColorsPosition]);

  useEffect(() => {
    if (values.noOfColours) {
      closePC();
    }
  }, [values.noOfColours]);

  const validate = async (schema, values) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {};
    } catch (err) {
      const errors = {};
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      return errors;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    const currentSchema = validationSchemas[step];
    const currentStepValues = getStepValues(step);
    const currentErrors = await validate(currentSchema, currentStepValues);
    setErrors(currentErrors);

    if (Object.keys(currentErrors).length === 0) {
      setStep((prevStep) => prevStep + 1);
    }
    scroll();
  };

  function reset() {
    setStep(0);
    // clear inputs
    setValues({
      colour: "",
      quantity: "",
      branding: "",
      noOfColours: "",
      printPosition: "",
      deliveryLocation: "UK (Exc. Highlands & Islands)",
    });
    closePC();
  }

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
    closePC();
    scroll();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalSchema = validationSchemas[step];
    const finalErrors = await validate(finalSchema, values);
    setErrors(finalErrors);

    if (Object.keys(finalErrors).length === 0) {
      // Submit form data here

      fetch(`${apiSrc}generalUpdate`, {
        method: "POST",
        body: JSON.stringify({
          request_type: "getPriceBuyOnline",
          code: code,
          colour: values.colour,
          quantity: values.quantity,
          branding: values.branding,
          noOfColours: values.noOfColours,
          printPosition: values.printPosition,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === 200) {
            //notifySuccess(data?.message);
            setPriceValues(() => ({
              carriage_cost: data?.message?.data?.carriage_cost,
              color_cost: data?.message?.data?.color_cost,
              origination: data?.message?.data?.origination,
              totalCostExVat: data?.message?.data?.totalCostExVat,
              vat: data?.message?.data?.vat,
              totalCostIncVat: data?.message?.data?.totalCostIncVat,
            }));
            openPC();
          } else {
            notifyFail(data?.message ? data?.message : "Something went wrong!");
          }
        })
        .catch((error) => {
          notifyFail("Something went wrong!");
          console.error(error);
        })
        .finally(() => {});
    }
  };

  const getStepValues = (step) => {
    switch (step) {
      case 0:
        return { deliveryLocation: values.deliveryLocation };
      case 1:
        return {
          colour: values.colour,
          quantity: values.quantity,
        };
      case 2:
        return {
          branding: values.branding,
          noOfColours: values.noOfColours,
          printPosition: values.printPosition,
        };
      default:
        return {};
    }
  };

  const Step1 = () => (
    <div className="row gift-finder">
      <div className="col-md-12">
        <label>Delivery Location:</label>
        <select
          aria-label="deliveryLocation step 1"
          name="deliveryLocation"
          value={values.deliveryLocation}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="UK (Exc. Highlands & Islands)">
            UK (Exc. Highlands & Islands)
          </option>
          {/* <option value="other">All other locations</option> */}
        </select>
        {errors.deliveryLocation && (
          <div className="errorFeild">{errors.deliveryLocation}</div>
        )}
      </div>
    </div>
  );

  const Step2 = () => (
    <div className="row gift-finder">
      <div className="col-md-6">
        <label>Product Colour:</label>
        <select
          aria-label="colour step 2"
          name="colour"
          value={values.colour}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select color</option>
          {data?.colors.map((color, index) => (
            <option key={index} value={color}>
              {color}
            </option>
          ))}
        </select>
        {errors.colour && <div className="errorFeild">{errors.colour}</div>}
      </div>
      <div className="col-md-6">
        <label>Quantity:</label>
        <select
          aria-label="quantity step 2"
          name="quantity"
          value={values.quantity}
          onChange={handleChange}
          className="form-control select-light"
        >
          <option value="">Select quantity</option>
          {data?.ProductQuantity.map((qty1, index) => (
            <option key={index} value={qty1}>
              {qty1}
            </option>
          ))}
        </select>
        {errors.quantity && <div className="errorFeild">{errors.quantity}</div>}
      </div>
    </div>
  );

  function step3() {
    return (
      <div className="row gift-finder">
        <div className="col-md-4">
          <label>Branding:</label>
          <select
            aria-label="Branding step 3"
            name="branding"
            value={values.branding}
            onChange={handleChange}
            className="form-control select-light"
          >
            <option value="">Select branding</option>
            {brandingOptions.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
          {errors.branding && (
            <div className="errorFeild">{errors.branding}</div>
          )}
        </div>
        <div className="col-md-4">
          <label>No Of Colours:</label>
          <select
            aria-label="noOfColours step 3"
            name="noOfColours"
            value={values.noOfColours}
            onChange={handleChange}
            className="form-control select-light"
          >
            <option value="">Select no of colours</option>
            {noOfColoursOptions.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
          {errors.noOfColours && (
            <div className="errorFeild">{errors.noOfColours}</div>
          )}
        </div>
        <div className="col-md-4">
          <label>Print Position:</label>
          <select
            aria-label="printPosition step 3"
            name="printPosition"
            value={values.printPosition}
            onChange={handleChange}
            className="form-control select-light"
          >
            <option value="">Select print position</option>
            {printPositionOptions.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
          {errors.printPosition && (
            <div className="errorFeild">{errors.printPosition}</div>
          )}
        </div>
      </div>
    );
  }

  function checkUserIfloggedIn() {
    const cookie = getCookie("token");
    if (cookie) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          request_type: "updateCart",
          code: code,
          colour: values.colour,
          quantity: values.quantity,
          branding: values.branding,
          noOfColours: values.noOfColours,
          printPosition: values.printPosition,
          deliveryLocation: values.deliveryLocation,
        }),
      };
      fetch(`${apiSrc}personal_update`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === 200) {
            //notifySuccess(data?.message);
            navigate("/checkout");
          } else {
            notifyFail(data?.message ? data?.message : "Something went wrong!");
          }
        })
        .catch((error) => {
          notifyFail("Something went wrong!");
          console.error(error);
        })
        .finally(() => {});
    } else toggleLGShow();
  }

  return (
    <>
      <div className="small-header header-bottom-grey">
        <h3>Please fill in the details for ordering online:</h3>
      </div>
      <Stepper
        styleConfig={{
          completedBgColor: "#925f96",
          activeBgColor: "#ff5e29",
        }}
        steps={[
          { label: "1. Product" },
          { label: "2. Branding " },
          { label: "3. Requirements" },
        ]}
        activeStep={step}
      />
      <form onSubmit={handleSubmit}>
        {step === 0 && <Step1 />}
        {step === 1 && <Step2 />}
        {step === 2 && step3()}
        <div className="container text-right mt-3">
          {step > 0 && (
            <button
              type="button"
              onClick={handlePrevious}
              className="btn btn-orange   mr-3"
            >
              Previous
            </button>
          )}
          {step < validationSchemas.length - 1 && (
            <button
              type="button"
              onClick={handleNext}
              className="btn btn-light-green "
            >
              Next
            </button>
          )}
          {step === validationSchemas.length - 1 && (
            <button type="submit" className="btn btn-light-green float-right">
              Get pricing
            </button>
          )}
        </div>
      </form>
      <div
        id="hiddencontainer"
        ref={pricingRef}
        className={`row pricingcontainer pcontent ${
          openPricingContainer ? "open" : ""
        }`}
      >
        <div className="col-md-12">
          <div className="small-header header-bottom-grey">
            <h3>Order Summary</h3>
            <span className="clear-btn">
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "transparent",
                }}
                onClick={reset}
              >
                Reset
              </button>
            </span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="specs-box-2">
            <span className="left">Product : </span>
            <span className="right">
              {code} - {name}
            </span>
          </div>
          <div className="specs-box-2">
            <span className="left">Branding : </span>
            <span className="right">
              <span id="brandingSpan">{values.branding}</span>
            </span>
          </div>
          <div className="specs-box-2">
            <span className="left">Quantity :</span>
            <span className="right">
              <span id="quantitySpan">{values.quantity}</span>
            </span>
          </div>
          <div className="specs-box-2">
            <span className="left">Product Colour : </span>
            <span className="right">
              <span id="colourSpan">{values.colour}</span>
            </span>
          </div>
          <div className="specs-box-2">
            <span className="left">No of Colours : </span>
            <span className="right">
              <span id="noOfColourSpan">{values.noOfColours}</span>
            </span>
          </div>
          <div className="specs-box-2">
            <span className="left">Print Position : </span>
            <span className="right">
              <span id="positionSpan">{values.printPosition}</span>
            </span>
          </div>
          <div className="specs-box-2">
            <span className="left">Delivery Location : </span>
            <span className="right">
              <span id="locationSpan">{values.deliveryLocation}</span>
            </span>
          </div>
        </div>
        <table className="table table-cart-pricing mt-2 mb-0">
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th className="text-right">Unit Price</th>
              <th className="text-right">Pricing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="header">Product Price:</td>
              <td>
                <span id="quantity2Span">{values.quantity}</span>
              </td>
              <td className="text-right">
                £
                <span id="color_costSpan">
                  {formatNumber(parseFloat(pricevalues.color_cost))}
                </span>
              </td>
              <td className="text-right">
                £
                <span id="SubTotalEachSpan">
                  {formatNumber(
                    parseFloat(values.quantity) *
                      parseFloat(pricevalues.color_cost)
                  )}
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td className="header text-right">Origination:</td>
              <td className="text-right">
                £
                <span id="originationSpan">
                  {formatNumber(parseFloat(pricevalues.origination))}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className="header text-right">Delivery:</td>
              <td className="text-right">
                £
                <span id="carriage_costSpan">
                  {formatNumber(parseFloat(pricevalues.carriage_cost))}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className="header text-right">Sub-total:</td>
              <td className="text-right">
                £
                <span id="totalCostExVatSpan">
                  {formatNumber(parseFloat(pricevalues.totalCostExVat))}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className="header text-right">VAT 20%</td>
              <td className="text-right">
                £
                <span id="vatSpan">
                  {formatNumber(parseFloat(pricevalues.vat))}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className="primary-header text-right">Total:</td>
              <td className="primary-header text-right">
                £
                <span id="totalCostIncVat">
                  {formatNumber(parseFloat(pricevalues.totalCostIncVat))}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="col-md-12">
          <hr style={{ margin: "16px 0px", borderTop: "2px solid #39C4BA" }} />
        </div>

        <div className="col-md-6"></div>
        <div className="col-md-6">
          <button
            onClick={checkUserIfloggedIn}
            id="proceedCheckoutBtn"
            className="btn btn-light-green-full mb-2"
          >
            PROCEED TO CHECKOUT
          </button>
        </div>
      </div>
    </>
  );
};
