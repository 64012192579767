import useFetch from "../../hooks/useFetch";
import { authHeader, getCookie, getSlug, notifyFail } from "../../utils";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { apiSrc, gUrl } from "../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LoginModal } from "../modals/login";
import { RegisterModal } from "../modals/register";
import { Form, Formik, Field } from "formik";
import { validationSchemaGift } from "../../utils/validationSchema";
import { OffcanvasMenu } from "../menu";
import { MockMenu, MockSlug } from "../../BFF/Mocks/mocks";

function Header() {
  const navigate = useNavigate();
  const { data: menuData } = useFetch(`general?type=menu`);
  const { data: slugData } = useFetch(`general?type=slug`);
  const { data: categoryData } = useFetch(`general?type=categories`);

  var telephone = getSlug(MockSlug, "telephone");
  var email = getSlug(MockSlug, "email");
  const [lshow, setlShow] = useState(false);
  const [rshow, setrShow] = useState(false);
  const lhandleClose = () => setlShow(false);
  const lShow = () => setlShow(true);
  const rhandleClose = () => setrShow(false);
  const token = getCookie("token");

  async function checkCart() {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "checkout",
      }),
    };

    try {
      const response = await fetch(`${apiSrc}personal`, requestOptions);
      const data = await response.json();
      if (data?.status === 200) navigate("/checkout");
      else notifyFail("There are no items in your cart!");
    } catch (error) {
      console.error(error);
    }
  }

  const regOpen = () => {
    setlShow(false);
    setrShow(true);
  };

  const logOpen = () => {
    setrShow(false);
    setlShow(true);
  };

  if (slugData !== null) {
    telephone = getSlug(slugData, "telephone");
    email = getSlug(slugData, "email");
  }

  const mainMenu = menuData
    ? menuData?.message?.menu.filter((item) => item.title === "Main Menu")[0]
        .content
    : [];

  const menu =
    mainMenu.length > 0
      ? JSON.parse(mainMenu)
      : JSON.parse(
          MockMenu?.message?.menu.filter(
            (item) => item.title === "Main Menu"
          )[0].content
        );

  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <div>
      <>
        <div className="page-wrapper">
          <header className="header">
            <div className="header-top">
              <div className="container">
                <div className="header-left"></div>
                <div className="header-right header-dropdowns" id="main-menu">
                  <ul className="nav main">
                    {menu.map((item) =>
                      item.children ? (
                        <li key={item.href} className="dropdown-submenu">
                          <div className="item">
                            <i className={item.icon} />
                            <a
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                              href={item.href}
                            >
                              {item.text}
                            </a>
                          </div>
                          <ul className="dropdown-menu">
                            {item.children.map((child) => (
                              <li key={child.href}>
                                <div className="item">
                                  <Link title={child.text} to={child.href}>
                                    <i className={child.icon} /> {child.text}
                                  </Link>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : (
                        <li key={item.href}>
                          <div className="item">
                            <Link title={item.text} to={item.href}>
                              <i className={item.icon} /> {item.text}
                            </Link>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-top-mobile bg-green">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h4>
                      Call us on
                      <a href={"tel:" + telephone} className="contact-phone">
                        {" " + telephone}
                      </a>
                    </h4>
                  </div>
                  <div className="col-md-12">
                    <h4>
                      or email us at
                      <a href={"mailto:" + email}> {" " + email}</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-middle sticky-header">
              <div className="container">
                <div className="header-left">
                  <Link title={"homepage"} to="/" className="logo">
                    <LazyLoadImage
                      src={`${gUrl}/assets/images/logos/promotional-gifts-logo.png`}
                      alt="Mindvision media ltd logo"
                      title="Mindvision media ltd logo"
                      width={"288px"}
                      height={"65.8px"}
                    />
                  </Link>
                </div>
                <div className="header-center"> </div>

                <div className="header-right">
                  <div className="contact-widget">
                    <h4 className="widget-title">CALL US NOW</h4>
                    <a href={"tel:" + telephone} className="contact-phone">
                      {" " + telephone}
                    </a>
                    <div className="contact-chat">
                      or email us at
                      <a href={"mailto:" + email}> {" " + email}</a>
                    </div>
                  </div>
                  <div className="horizontal_bar"></div>
                  {token ? (
                    <Link title={"My account"} to="/personal-details">
                      <div className="header-user">
                        <FeatherIcon style={{ color: "#39C4BA" }} icon="user" />
                        <div className="header-userinfo">
                          <h4>My account</h4>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <button
                      aria-label="login Modal btn"
                      onClick={lShow}
                      data-target="#loginModal"
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <div className="header-user">
                        <FeatherIcon style={{ color: "#39C4BA" }} icon="user" />
                        <div className="header-userinfo">
                          <h4>Login</h4>
                        </div>
                      </div>
                    </button>
                  )}

                  <div className="porto-icon">
                    <div>
                      <Formik
                        initialValues={{ search: "" }}
                        validationSchema={validationSchemaGift()}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          const search = values?.search ? values?.search : "-";
                          const category = values?.category
                            ? values?.category
                            : "-";
                          const price = values?.price ? values?.price : "-";
                          const colour = values?.colour ? values?.colour : "-";
                          navigate(
                            `/gift-finder/${search}/${category}/${price}/${colour}`
                          );
                          resetForm();
                        }}
                      >
                        <Form style={{ margin: "0px", background: "#fff0" }}>
                          <Field
                            type="text"
                            name="search"
                            aria-label="Search"
                          />
                          <button type="submit" aria-label="search button">
                            <i
                              style={{ color: "#fff", fontSize: "20px" }}
                              className="icon-search"
                            ></i>
                          </button>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                  <OffcanvasMenu show={show} onHide={handleToggle} />
                  <Link
                    to="/gift-finder/-/-/-/-/"
                    aria-label="search mobile toggler"
                    className="search-toggler"
                  >
                    <FeatherIcon style={{ color: "#39C4BA" }} icon="search" />
                  </Link>
                  <button
                    aria-label="mobile menu toggler"
                    className="mobile-menu-toggler"
                    type="button"
                    onClick={handleToggle}
                  >
                    <i style={{ color: "#26CCCA" }} className="icon-menu"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="header-bottom sticky-header">
              <div className="container">
                <div className="header-left">
                  <nav className="main-nav">
                    <ul className="menu">
                      {categoryData?.message?.categories?.length > 0 &&
                        categoryData?.message?.categories.map((item) => {
                          const subcategoryList =
                            item.subcategoryList.split("\n");
                          const categoryUrl = `/category/${item.categoryURL
                            .toLowerCase()
                            .replace(/\s+/g, "-")}`;
                          return (
                            <li className="" key={categoryUrl}>
                              <Link
                                title={item.categoryName}
                                to={categoryUrl}
                                className="sf-with-ul"
                              >
                                {item.categoryName}
                              </Link>
                              <div className="megamenu">
                                <div className="row">
                                  <div className="col-lg-9">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Link
                                          title={item.categoryName}
                                          to={categoryUrl}
                                          className="nolink"
                                        >
                                          {item.categoryName}
                                        </Link>
                                      </div>

                                      {Array.from(
                                        Array(
                                          Math.ceil(subcategoryList.length / 6)
                                        ),
                                        (_, i) => {
                                          const chunk = subcategoryList.slice(
                                            i * 6,
                                            (i + 1) * 6
                                          );
                                          return (
                                            <div
                                              className="col-md-3 border-right"
                                              key={i}
                                            >
                                              <ul className="submenu">
                                                {chunk.map((subitem) => {
                                                  const subcategoryUrl = `/category/${item.categoryURL
                                                    .toLowerCase()
                                                    .replace(
                                                      /\s+/g,
                                                      "-"
                                                    )}/${subitem}`;
                                                  return (
                                                    <li key={subitem}>
                                                      <Link
                                                        title={subitem}
                                                        to={subcategoryUrl}
                                                      >
                                                        {subitem}
                                                      </Link>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-3 image-container">
                                    <LazyLoadImage
                                      src={gUrl + item.categoryImage}
                                      alt={item.categoryName}
                                      title={item.categoryName}
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </header>
        </div>
        {token && (
          <button id="cart-top" onClick={checkCart}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-shopping-cart"
              style={{ color: "#fff" }}
            >
              <circle cx="9" cy="21" r="1"></circle>
              <circle cx="20" cy="21" r="1"></circle>
              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
            </svg>
          </button>
        )}
        <LoginModal show={lshow} close={lhandleClose} regOpen={regOpen} />
        <RegisterModal show={rshow} close={rhandleClose} logOpen={logOpen} />
      </>
    </div>
  );
}

export default Header;
