import { useParams } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";

export default function Page() {
  const { page } = useParams();
  const { data: pdata } = useFetch(`general?type=page&url=${page}`);
  const pagetitle = pdata ? pdata?.message?.page?.title : "";
  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: pagetitle,
      to: page,
    },
  ];
  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          <div
            className="row mb-2"
            dangerouslySetInnerHTML={{
              __html: pdata?.message?.page.description,
            }}
          />
        </>
      )}
    </div>
  );
}
