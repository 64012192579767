import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

export const DP = ({ changeDate, value = new Date() }) => {
  return (
    <DatePicker
      className="form-control select-light datepicker2"
      selected={value ? value : null}
      onChange={(date) => changeDate(date)}
      dateFormat="dd/MM/yyyy"
    />
  );
};
