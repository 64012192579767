import { SuperSEO } from "react-super-seo";
import { formatStringLowercasewithdash, getSlug } from ".";
import {
  structuredDataOrg,
  structuredDataOrgHomeStore,
  structuredDataSearch,
} from "./structuredData";
import { Helmet } from "react-helmet";
import { gUrl } from "./constants";

export function HomeSEO({ slugData }) {
  const hKeywords = getSlug(slugData, "homepage-meta-keywords")
    ? getSlug(slugData, "homepage-meta-keywords")
    : "";

  const hDescription = getSlug(slugData, "homepage-meta-description")
    ? getSlug(slugData, "homepage-meta-description")
    : "";

  const hTitle = getSlug(slugData, "homepage-meta-title")
    ? getSlug(slugData, "homepage-meta-title")
    : "";

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${gUrl}`} />
        <script type="application/ld+json">
          {JSON.stringify(structuredDataOrg)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(structuredDataOrgHomeStore)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(structuredDataSearch)}
        </script>
      </Helmet>
      <SuperSEO title={hTitle} description={hDescription}>
        <meta name="keywords" content={hKeywords} />
        <meta property="keywords" content={hKeywords} />
      </SuperSEO>
    </>
  );
}

export function ProductDetailsSEO({ pData, category }) {
  const structuredProductDetails = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: pData?.title ? pData?.title : "",
    image: gUrl + pData?.imgThumb,
    description: pData?.description,
    sku: pData?.code,
    mpn: pData?.code,
    brand: {
      "@type": "Brand",
      name: pData?.supplier,
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4",
        bestRating: "5",
      },
      author: {
        "@type": "Person",
        name: "Mindvision Media Ltd",
      },
    },
  };
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${gUrl}/promotional-merchandise/${category}/${
            pData?.code
          }/${formatStringLowercasewithdash(pData?.title)}`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredProductDetails)}
        </script>
      </Helmet>
      <SuperSEO
        title={pData?.title}
        description={pData?.description}
      ></SuperSEO>
    </>
  );
}
