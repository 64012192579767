import Breadcrumb from "../../components/breadcrumb";
import { apiSrc, stepsTexts } from "../../utils/constants";

import { Steps } from "../../components/stepper";
import { useCallback, useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { authHeader, notifyFail } from "../../utils";

export default function OrderComplete() {
  const navigate = useNavigate();
  const { oid } = useParams();
  const [hasChecked, setHasChecked] = useState(false);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Checkout",
      to: "/checkout",
    },
    {
      name: "Billing",
      to: "/billing",
    },
    {
      name: "Payment",
      to: "/payment",
    },
  ];

  const check = useCallback(() => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "stripe_check",
      }),
    };
    fetch(`${apiSrc}personal_update`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          navigate("/order-complete/" + data?.message?.oid);
        } else {
          navigate("/order-failed");
        }
      })
      .catch((error) => {
        notifyFail("Something went wrong!");
        console.error(error);
      })
      .finally(() => {});
  }, [navigate]);

  useEffect(() => {
    if (!hasChecked && !oid) {
      check();
      setHasChecked(true);
    }
  }, [hasChecked, check, oid]);

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Steps active={3} steps={stepsTexts} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-2 mb-4">
              <div className="order-summary">
                <h3 className="step-title mb-2">
                  Order #{oid} placed successfully!
                </h3>
                <p>
                  Dear Customer, <br />
                  Thank you, we’ve received your order #{oid} and now require
                  your artwork. Please email this to{" "}
                  <a
                    className="btn btn-link p-0"
                    href="mailto:production@mindvision.co.uk?subject=Order #<?php if(isset($_SESSION['Temp_order_no'])){ echo $_SESSION['Temp_order_no'] ; } ?> Artwork"
                  >
                    production@mindvision.co.uk
                  </a>
                  .<br /> Also, please note that you can view your order details{" "}
                  <a className="btn btn-link p-0" href={`/order/${oid}`}>
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
