import { useParams } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { gUrl } from "../../utils/constants";
import { results } from "../../utils";
import SProduct from "../../components/SProduct";

export default function Promotions() {
  const { id } = useParams();
  const { data: pdata } = useFetch(`general?type=promotion&url=${id}`);
  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Promotions",
    },
    {
      name: pdata?.message?.item?.title,
    },
  ];
  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <div className="container">
          <div
            class="row mb-2 promo-header"
            style={{ background: "backgroundColor" }}
          >
            <div class="col-3">
              <LazyLoadImage
                className="img-responsive lazyload"
                src={`${gUrl}${pdata?.message?.item?.image}`}
                alt={pdata?.message?.item?.pageTitle}
                title={pdata?.message?.item?.pageTitle}
                width={"188px"}
                height={"165.67"}
              />
            </div>
            <div class="col-9">
              <h1> {pdata?.message?.item?.pageTitle} </h1>
              <p> {pdata?.message?.item?.description} </p>
            </div>
          </div>
          <div className="row">
            {pdata?.message?.products.map((item) => {
              return (
                <div className="col-md-3" key={item.url}>
                  <SProduct key={item.url} item={item} />
                </div>
              );
            })}
            {pdata?.message?.products?.length === 0 && (
              <>{results("No results available")}</>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
