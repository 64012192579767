import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import useFetch from "../../hooks/useFetch";
import "./index.css";
import { gUrl } from "../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { getSlug } from "../../utils";
import SProduct from "../../components/SProduct";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HomeSEO } from "../../utils/SEO";
import { Button } from "../../components/button";
import { validationSchemaGift } from "../../utils/validationSchema";
import { Form, Formik } from "formik";
import { InputField } from "../../components/input-field";
import { SelectField } from "../../components/select-field";
import demoImage from "../../assets/images/demo.jpg";
import { MockBanners, MockGiftFinder } from "../../BFF/Mocks/mocks";

function DisplayProducts({ array, title }) {
  const details = array[0]?.productDetails ? array[0]?.productDetails : array;
  return (
    <div className="container mt-3">
      <section style={{ position: "relative" }}>
        <div className="section-title">
          <h2 className="mt-1 mb-3 mr-5">{title.toUpperCase()}</h2>
        </div>
        <div className="featured-section bg-white">
          <Swiper
            slidesPerView={5}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mySwiper"
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 480px
              674: {
                slidesPerView: 2,
              },
              // when window width is >= 640px
              900: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
              1300: {
                slidesPerView: 5,
              },
            }}
          >
            {details.map((item) => {
              return (
                <SwiperSlide key={item.url}>
                  <SProduct item={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </div>
  );
}

const Home = () => {
  const navigate = useNavigate();
  const { data: bannerData } = useFetch(`general?type=banners`);
  const { data: slugData } = useFetch(`general?type=slug`);
  const { data: productsData } = useFetch(`general?type=home-products`);
  // const { data: ppData } = useFetch(`general?type=popular-products`);
  // const { data: categoryData } = useFetch(`general?type=categories`);
  // const { data: giftData } = useFetch("gift-finder");  // in future for  gift finder
  const giftData = MockGiftFinder;
  var telephone = null;
  var email = null;

  if (slugData !== null) {
    telephone = getSlug(slugData, "telephone");
    email = getSlug(slugData, "email");
  }

  const mainArray =
    bannerData != null
      ? bannerData.message.banners.filter((item) => item.position === "main")
      : MockBanners.message.banners.filter((item) => item.position === "main");
  const middleArray =
    bannerData != null
      ? bannerData.message.banners.filter((item) => item.position === "middle")
      : MockBanners.message.banners.filter(
          (item) => item.position === "middle"
        );
  const bottomArray =
    bannerData != null
      ? bannerData.message.banners.filter((item) => item.position === "bottom")
      : [];
  const fpArray =
    productsData != null
      ? productsData?.message?.products.filter(
          (item) => item.title === "FEATURED PRODUCTS"
        )
      : [];

  // const remainingArray =
  //   productsData != null
  //     ? productsData?.message.products.filter(
  //         (item) => item.title !== "FEATURED PRODUCTS"
  //       )
  //     : [];

  return (
    <main className="home main">
      {slugData != null && <HomeSEO slugData={slugData} />}
      <div className="container">
        <section className="mb-2">
          <div className="row row-sm">
            <div className="col-lg-8 col-md-8">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {mainArray.map((item, index) => {
                  return (
                    <SwiperSlide key={item.background}>
                      <Link
                        title={"Carousal background " + index + 1}
                        to={item.link}
                      >
                        <LazyLoadImage
                          alt={"Carousal background " + index + 1}
                          title={"Carousal background " + index + 1}
                          src={gUrl + item.background}
                          fallback={demoImage}
                        />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className=" col-lg-4 col-md-4">
              <div className="gift-finder home-banner3 text-center">
                <h3>
                  Gift Finder
                  <br />
                  <span>Browse Gift Suggestions</span>
                </h3>
                <Formik
                  initialValues={{ search: "" }}
                  validationSchema={validationSchemaGift()}
                  onSubmit={(values, { setSubmitting }) => {
                    const search = values?.search ? values?.search : "-";
                    const category = values?.category ? values?.category : "-";
                    const price = values?.price ? values?.price : "-";
                    const colour = values?.colour ? values?.colour : "-";

                    navigate(
                      `/gift-finder/${search}/${category}/${price}/${colour}/`
                    );
                  }}
                >
                  <Form className="m-0">
                    <div className="select-box">
                      <InputField
                        name={"search"}
                        type={"text"}
                        label={"Search"}
                        placeholder="By keyword"
                      />
                      <SelectField
                        ariaLabel="Select a category"
                        name={"category"}
                      >
                        <option value="">Any category</option>
                        {giftData?.categories &&
                          giftData?.categories.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </SelectField>
                      <SelectField
                        ariaLabel="Select a price range"
                        name={"price"}
                      >
                        <option value="">Any price</option>
                        <option value="0 AND 1">£0 - £1</option>
                        <option value="1 AND 2">£1 - £2</option>
                        <option value="2 AND 5">£2 - £5</option>
                        <option value="5 AND 10">£5 - £10</option>
                        <option value="10 AND 20">£10 - £20</option>
                        <option value="20 AND 30">£20 - £30</option>
                        <option value="30 AND 40">£30 - £40</option>
                        <option value="40 AND 50">£40 - £50</option>
                        <option value="50 AND 100">£50 - £100</option>
                        <option value="100 AND 1000000">£100+</option>
                      </SelectField>

                      <SelectField name={"colour"} ariaLabel="Select a color">
                        <option value="">Any colour</option>
                        {giftData?.colors &&
                          giftData?.colors.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </SelectField>
                    </div>
                    <Button
                      type="submit"
                      title={"SEARCH"}
                      variant={"btn-dark-green"}
                    />
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-2 pt-5 pb-5">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mySwiper"
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 480px
              674: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              900: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {middleArray.map((item, index) => {
              return (
                <SwiperSlide key={item.background}>
                  <div className="banner banner1 banner-sm-vw">
                    <figure>
                      <LazyLoadImage
                        src={gUrl + item.background}
                        alt={"Banner " + (index + 1)}
                        title={"Banner " + (index + 1)}
                      />
                    </figure>
                    <div className="banner-layer banner-layer-middle">
                      <Link
                        title={"Banner " + (index + 1)}
                        to={item.link}
                        className="btn btn-banner-top ls-10"
                      >
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
        <section className="mb-2">
          <div className="container">
            <section className="service-section mt-2 mb-2">
              <div className="col-lg-4">
                <div className="service-widget">
                  <i className="service-icon fa fa-comments"></i>
                  <div className="service-content">
                    <h3 className="service-title">CONTACT US NOW</h3>
                    <p>
                      On <a href={"tel:" + telephone}> {telephone} </a> or{" "}
                      <a href={"mailto:" + email}> {email} </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="service-widget">
                  <i className="service-icon fas fa-palette"></i>
                  <div className="service-content">
                    <h3 className="service-title">IN-HOUSE DESIGN STUDIO</h3>
                    <p>Design & artwork should you need it</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="service-widget">
                  <i className="service-icon far fa-life-ring"></i>
                  <div className="service-content">
                    <h3 className="service-title">NEED IT LAST MINUTE?</h3>
                    <p>
                      No time left? Email:{" "}
                      <a href="mailto:emergency@mindvision.co.uk">
                        emergency@mindvision.co.uk
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* Featured products */}
        {fpArray !== null && (
          <DisplayProducts array={fpArray} title={"FEATURED PRODUCTS"} />
        )}
      </div>
      {/* Shop */}
      <section className="bg-grey pt-5 pb-5">
        <div className="container mt-1 mb-1">
          <div className="row row-sm">
            {bottomArray.map((item) => {
              return (
                <div className="col-md-4" key={item.link}>
                  <Link title={item.title} to={item.link}>
                    <div
                      className="home-banner5"
                      style={{
                        background: `url(${gUrl + item.background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="banner-content">
                        <span> {item.title} </span>
                        <h3> {item.subTitle} </h3>
                      </div>
                      <div
                        className="banner-circle"
                        style={{
                          background: `url(${gUrl + item.images})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* popular products */}
      {/* <div className="container">
            {ppData !== null && (
              <DisplayProducts
                array={ppData.message.products}
                title={"POPULAR PRODUCTS"}
              />
            )}
          </div> */}
      {/* Browse our categories */}
      {/* <div className="banners-section mb-5">
            <div className="container">
              <h2 className="subtitle text-center">
                <span>BROWSE OUR CATEGORIES</span>
              </h2>
              <div>
                <Swiper
                  slidesPerView={5}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Navigation]}
                  navigation={true}
                  className="mySwiper"
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    674: {
                      slidesPerView: 2,
                    },
                    // when window width is >= 640px
                    900: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                    1300: {
                      slidesPerView: 5,
                    },
                  }}
                >
                  {categoryData?.message?.categories?.length > 0 &&
                    categoryData?.message?.categories.map((item, index) => {
                      return (
                        <SwiperSlide key={item.categoryURL}>
                          <div className="banner banner-image">
                            <Link
                              title={item.categoryName}
                              to={`/category/${item.categoryURL}`}
                            >
                              <LazyLoadImage
                                src={gUrl + item.categoryImage}
                                alt={item.categoryName}
                                title={item.categoryName}
                              />
                            </Link>
                            <h5 className="text-center pt-2 pb-2">
                              {item.categoryName}
                            </h5>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div> */}
      {/* Remaining */}
      {/* <div className="container">
            {remainingArray.map((item) => {
              return (
                <DisplayProducts
                  array={item.productDetails}
                  title={item.title}
                  key={item.title}
                />
              );
            })}
          </div> */}
    </main>
  );
};

export default Home;
