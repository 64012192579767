import { Link } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { errorCode } from "../../utils";
import { ProfileMenu } from "../../components/profile-menu";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Quotes() {
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "quotes",
  });

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "User Profile",
      to: "/personal-details",
    },
    {
      name: "Quotes",
    },
  ];

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>My Quotes:</h3>
              </div>
              {personalData?.message?.quotes.length === 0 && (
                <div className="alert alert-warning" role="alert">
                  No Quote Requests yet!
                </div>
              )}
              {personalData?.message?.quotes.map((quote, index) => {
                return (
                  <div className="orders-row" key={index}>
                    <div className="row">
                      <div className="col-3 col-sm-3 col-md-3">
                        <figure className="product-image-container">
                          <Link to={quote?.link} className="product-image">
                            <LazyLoadImage src={quote?.image} />
                          </Link>
                        </figure>
                      </div>
                      <div className="col-9 col-sm-6 col-md-6 align-self-center">
                        <Link to={quote?.link}>
                          <h2 className="text"> {quote?.title} </h2>
                          <span className="qty">Qty: {quote?.quantity} </span>
                        </Link>
                      </div>
                      <div className="col-sm-3 col-md-3 align-self-center">
                        <div>
                          <Link
                            to={quote?.link}
                            className="btn btn-light-green mb-1"
                          >
                            VIEW QUOTE
                          </Link>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <hr className="mt-1 mb-3" />
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-3 col-md-3">
                        <p className="bottom-head">REQUEST NO:</p>
                        <span className="bottom-value"># {quote?.id} </span>
                      </div>
                      <div className="col-5 col-md-3">
                        <p className="bottom-head">REQUEST PLACED: </p>
                        <span className="bottom-value">{quote?.date}</span>
                      </div>
                      <div className="col-4 col-md-3">
                        <p className="bottom-head">TOTAL: </p>
                        <span className="bottom-value">Pending</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
