import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./index.css";
import { paypal_api_key } from "../../utils/constants";

export const Paypal = ({ initialOptions, onApprove }) => {
  const options = initialOptions
    ? initialOptions
    : {
        clientId: paypal_api_key,
        currency: "GBP",
        intent: "capture",
      };

  return (
    <PayPalScriptProvider options={options}>
      <PayPalButtons
        onApprove={(data) => onApprove(data)}
        style={{ layout: "horizontal" }}
        disableFunding="credit,debit"
      />
    </PayPalScriptProvider>
  );
};
