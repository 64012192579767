import BModal from "../modal";
import { Button } from "../button";
import { validationSchemaAddress } from "../../utils/validationSchema";
import { Form, Formik } from "formik";
import { InputField } from "../../components/input-field";
import { apiSrc } from "../../utils/constants";
import {
  authHeader,
  fetchAddresses,
  notifyFail,
  notifySuccess,
} from "../../utils";
import { useState } from "react";
import { SelectField } from "../select-field";

export const AddressModal = ({ show, close, pid, editAddress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [timer, setTimer] = useState(null);

  const request_type = editAddress
    ? "editAddressCustomer"
    : "addAddressCustomer";
  const person = editAddress ? JSON.parse(editAddress?.personDetails) : null;

  const handleSearch = (e) => {
    const value = e.target.value;
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        handleInputChangeAfterDelay(value);
        setTimer(null); // reset the timer
      }, 1000)
    );
  };

  const handleInputChangeAfterDelay = (value) => {
    fetchAddresses(value)
      .then((data) => {
        setAddresses(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <BModal show={show} close={close}>
      <Formik
        initialValues={{
          request_type: request_type,
          pid: pid,
          aid: editAddress ? editAddress?.addressID : "",
          fname: person ? person?.firstname : "",
          lName: person ? person?.lastname : "",
          email: person ? person?.email : "",
          telephone: person ? person?.phone : "",
          mCompany: editAddress ? editAddress?.companyName : "",
          address1: editAddress ? editAddress?.address1 : "",
          address2: editAddress ? editAddress?.address2 : "",
          town: editAddress ? editAddress?.town : "",
          county: editAddress ? editAddress?.county : "",
          postcode: editAddress ? editAddress?.postcode : "",
        }}
        validationSchema={validationSchemaAddress()}
        onSubmit={(values, { setSubmitting }) => {
          const payload = values;

          const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(payload),
          };
          setIsLoading(true);
          fetch(`${apiSrc}personal_update`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data?.status === 200) {
                notifySuccess(data?.message);
                close();
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                notifyFail(
                  data?.message ? data?.message : "Something went wrong!"
                );
              }
            })
            .catch((error) => {
              notifyFail("Something went wrong!");
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {(props) => {
          const { setFieldValue } = props;

          const handleSelect = (e) => {
            const value = e.target.value;
            if (value !== "") {
              const address = JSON.parse(value);
              setFieldValue("mCompany", address?.organisation);
              setFieldValue("address1", address?.addressline1);
              setFieldValue("address2", address?.addressline2);
              setFieldValue("town", address?.posttown);
              setFieldValue("county", address?.county);
              setFieldValue("postcode", address?.postcode);
            }
          };

          return (
            <Form className="m-0">
              <h3 className="heading-1 mt-2 mb-2">ADD NEW ADDRESS!</h3>

              <div className="row">
                <div className="col-md-6">
                  <InputField
                    name={"fname"}
                    type={"text"}
                    label={"First Name:"}
                    placeholder="First Name"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    name={"lName"}
                    type={"text"}
                    label={"Last Name:"}
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    name={"email"}
                    type={"email"}
                    label={"Email:"}
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    name={"telephone"}
                    type={"number"}
                    label={"Phone No:"}
                    placeholder="Phone No"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="small-header header-bottom-grey mb-2">
                    <h3>Address Details:</h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="" id="address_finder">
                    <div className="form-group">
                      <label
                        htmlFor="postcoder_autocomplete"
                        id="postcoder_autocomplete_label"
                      >
                        Type in your address or postcode :
                      </label>
                      <input
                        id="postcoder_autocomplete"
                        type="text"
                        className="form-control select-light"
                        onChange={handleSearch}
                      />
                    </div>
                    {addresses?.length > 0 && (
                      <select
                        className="cselect cselectstyle"
                        onChange={handleSelect}
                      >
                        <option value="">Select an address</option>
                        {addresses.map((address, index) => {
                          return (
                            <option key={index} value={JSON.stringify(address)}>
                              {address.summaryline}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                  <div className="mb-2 header-bottom-grey"></div>
                </div>
                <div className="col-md-12">
                  <InputField
                    name={"mCompany"}
                    type={"text"}
                    label={"Company"}
                    placeholder="Company"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    id={"address1"}
                    name={"address1"}
                    type={"text"}
                    label={"Address Line 1"}
                    placeholder="Address Line 1"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    name={"address2"}
                    type={"text"}
                    label={"Address Line 2"}
                    placeholder="Address Line 2"
                  />
                </div>

                <div className="col-md-6">
                  <InputField
                    name={"town"}
                    type={"text"}
                    label={"City / Town"}
                    placeholder="City / Town"
                  />
                </div>

                <div className="col-md-6">
                  <InputField
                    name={"county"}
                    type={"text"}
                    label={"County / State / Region"}
                    placeholder="County / State / Region"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    name={"postcode"}
                    type={"text"}
                    label={"Postcode / Zipcode"}
                    placeholder="Postcode / Zipcode"
                  />
                </div>

                <div className="col-md-6  ">
                  <SelectField
                    label={"Country"}
                    name={"Country"}
                    onChange={(e) => {}}
                  >
                    <option value="GB">United Kingdom</option>
                  </SelectField>
                </div>
              </div>

              <div className="form-footer">
                <div className="form-footer-right">
                  <Button
                    className="pt-2 pb-2 text-center"
                    variant={"btn-light-green-full"}
                    type="submit"
                    title={"Save"}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </BModal>
  );
};
