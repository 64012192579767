import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { apiSrc } from "../../utils/constants";
import { getSlug, notifyFail, notifySuccess } from "../../utils";
import { InputField } from "../../components/input-field";
import { Form, Formik } from "formik";
import { validationSchemaContact } from "../../utils/validationSchema";
import { useState } from "react";
import { Button } from "../../components/button";
import { TextArea } from "../../components/input-field/textArea";
import "./index.css";

export default function ContactUs() {
  const { data: slugData } = useFetch(`general?type=slug`);
  const [isLoading, setIsLoading] = useState(false);
  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Contact us",
      to: "/contact",
    },
  ];

  var telephone = null;
  var email = null;
  var address = null;

  if (slugData !== null) {
    telephone = getSlug(slugData, "telephone");
    email = getSlug(slugData, "email");
    address = getSlug(slugData, "address");
  }

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h2 className="step-title mb-2">CONTACT MINDVISION</h2>
            <div
              className="mb-2"
              dangerouslySetInnerHTML={{
                __html: address,
              }}
            />
            <p>
              T: <a href={`tel:${telephone}`}> {telephone} </a>
              <br />
              E: <a href={`mailto:${email}`}> {email}</a>
            </p>
          </div>
          <div className="col-md-8">
            <h2 className="step-title mb-2">HAVE A QUESTION?</h2>
            <Formik
              initialValues={{
                request_type: "contactForm",
                name: "",
                email: "",
                subject: "",
                message: "",
              }}
              validationSchema={validationSchemaContact()}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const payload = values;

                setIsLoading(true);
                fetch(`${apiSrc}generalUpdate`, {
                  method: "POST",
                  body: JSON.stringify(payload),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data?.status === 200) {
                      notifySuccess(data?.message);
                      resetForm();
                    } else {
                      notifyFail(
                        data?.message ? data?.message : "Something went wrong!"
                      );
                    }
                  })
                  .catch((error) => {
                    notifyFail("Something went wrong!");
                    console.error(error);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              <Form className="m-0">
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      label={"Name"}
                      name={"name"}
                      type={"text"}
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label={"Email"}
                      name={"email"}
                      type={"email"}
                      placeholder="Email"
                    />
                  </div>
                </div>

                <InputField
                  label={"Subject"}
                  name={"subject"}
                  type={"text"}
                  placeholder="Subject"
                />
                <TextArea
                  label={"Message"}
                  className="form-control"
                  name="message"
                />

                <div className="mb-5">
                  <Button
                    type="submit"
                    title={"Send Email"}
                    variant={"btn-light-green"}
                    isLoading={isLoading}
                    iconRight
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
