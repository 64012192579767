import { useParams } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { apiSrc } from "../../utils/constants";
import FeatherIcon from "feather-icons-react";
import {
  authHeader,
  dateFormat,
  getRatingAvg,
  notifyFail,
  notifySuccess,
  scrollHandle,
} from "../../utils";
import { useRef, useState } from "react";
import { Button } from "../../components/button";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "swiper/css/bundle";
import { QuoteMultiStep } from "../../components/multistep/quote";
import { QuoteModal } from "../../components/modals/quote";
import { OrderMultiStep } from "../../components/multistep/order";
import { LoginRegModal } from "../../components/modals/loginreg";
import { LoginModal } from "../../components/modals/login";
import { RegisterModal } from "../../components/modals/register";
import { Field, Form, Formik } from "formik";
import { validationSchemaReview } from "../../utils/validationSchema";
import { InputField } from "../../components/input-field";
import { TextArea } from "../../components/input-field/textArea";
import { ProductDetailsSEO } from "../../utils/SEO";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MockProductDetails } from "../../BFF/Mocks/mocks";

function PriceTable({ tData }) {
  return (
    <Tabs className={"inner-tabs"}>
      <TabList>
        {tData &&
          tData.map((item) => {
            return <Tab key={item?.name}>{item?.name}</Tab>;
          })}
      </TabList>
      {tData &&
        tData.map((item) => {
          return (
            <TabPanel key={item?.name}>
              <div
                className="tab-pane fade active pt-0 show"
                id="type-<?php echo $printTypekey; ?>"
                role="tabpanel"
              >
                <div className="table-responsive">
                  <table className="ratings-table mb-1 mt-0 ">
                    <thead>
                      <tr>
                        {item?.th.map((th) => {
                          return <th key={th}>{th}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {item?.tr.map((tr) => {
                        return (
                          <tr key={tr}>
                            {tr.map((d, index) => {
                              return (
                                <td key={index}>
                                  {index > 0 && "£"}
                                  {d}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="specs-box-2">
                    <span className="left">Print Area : </span>
                    <span className="right">{item?.personalisation_area}</span>
                  </div>
                  <div className="specs-box-2">
                    <span className="left">Print Position : </span>
                    <span className="right">
                      {item?.personalisation_position}
                    </span>
                  </div>
                </div>
              </div>
            </TabPanel>
          );
        })}
    </Tabs>
  );
}

function ProductDetail() {
  const { category, pcode } = useParams();
  const [review, setReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qshow, setQShow] = useState(false);
  const [lgshow, setLGShow] = useState(false);
  const [openQuote, setOpenQuote] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const quoteRef = useRef(null);
  const orderRef = useRef(null);
  const quoteToggleModal = () => setQShow(!qshow);
  const toggleLGShow = () => setLGShow(!lgshow);
  const { data: proData } = useFetch("product-details?code=" + pcode);

  const pData = proData != null ? proData : MockProductDetails;

  // ---------------login and registration-----------
  const [lshow, setlShow] = useState(false);
  const [rshow, setrShow] = useState(false);
  const lhandleClose = () => setlShow(false);
  const rhandleClose = () => setrShow(false);

  const regOpen = () => {
    setlShow(false);
    setrShow(true);
  };

  const logOpen = () => {
    setrShow(false);
    setlShow(true);
  };
  // ---------------------------------------

  const toggleOpenQuote = () => {
    setOpenQuote(!openQuote);
    if (!openQuote) {
      setOpenOrder(false);
      scrollHandle(quoteRef);
    }
  };

  const toggleOpenOrderSection = () => {
    setOpenOrder(!openOrder);
    if (!openOrder) {
      setOpenQuote(false);
      setTimeout(() => {
        scrollHandle(orderRef);
      }, 50);
    }
  };

  const scrollHandleOrder = () => {
    const target = orderRef.current;
    if (target) target.scrollIntoView({ behavior: "smooth" });
  };

  const showReviewForm = () => {
    setReview(true);
  };

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: "active", to: category },
  ];

  const ratingAvg = pData?.reviews ? getRatingAvg(pData?.reviews) : 0;
  const anyError = pData?.ProductQuantity?.errormsg;

  return (
    <div>
      {pData?.title && <ProductDetailsSEO pData={pData} category={category} />}
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      <div className="container">
        <div className="product-single-container product-single-default">
          <div className="row">
            <div className="col-lg-5 product-single-gallery">
              <div>
                <Swiper
                  spaceBetween={10}
                  freeMode={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwipertop"
                >
                  <SwiperSlide>
                    <LazyLoadImage
                      width={100}
                      height={"386.99px"}
                      title={pData?.title}
                      alt={pData?.title}
                      src={pData?.image}
                      // onError={(e) => {
                      //   e.currentTarget.src = pData?.image;
                      // }}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="d-none d-lg-block d-md-none">
                {review ? (
                  <Formik
                    initialValues={{
                      request_type: "writeReview",
                      pcode: pcode,
                      star: "",
                      title: "",
                      description: "",
                    }}
                    validationSchema={validationSchemaReview()}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      const payload = values;
                      setIsLoading(true);
                      fetch(`${apiSrc}personal_update`, {
                        method: "POST",
                        headers: authHeader(),
                        body: JSON.stringify(payload),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          if (data?.status === 200) {
                            notifySuccess(data?.message);
                            resetForm();
                            setReview(false);
                          } else {
                            notifyFail(
                              data?.message
                                ? data?.message
                                : "Something went wrong!"
                            );
                          }
                        })
                        .catch((error) => {
                          notifyFail("Something went wrong!");
                          console.error(error);
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    }}
                  >
                    <Form className="m-0">
                      <input type="hidden" name="pcode" value=" " />
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Rate this product:{" "}
                              <span className="required">*</span>
                            </label>
                            <div className="rating">
                              <Field
                                id="star5"
                                name="star"
                                type="radio"
                                value="5"
                                className="radio-btn hide"
                              />
                              <label htmlFor="star5">
                                <FeatherIcon
                                  style={{ width: "26px", height: "23px" }}
                                  icon="star"
                                />
                              </label>
                              <Field
                                id="star4"
                                name="star"
                                type="radio"
                                value="4"
                                className="radio-btn hide"
                              />
                              <label htmlFor="star4">
                                <FeatherIcon
                                  style={{ width: "26px", height: "23px" }}
                                  icon="star"
                                />
                              </label>
                              <Field
                                id="star3"
                                name="star"
                                type="radio"
                                value="3"
                                className="radio-btn hide"
                              />
                              <label htmlFor="star3">
                                <FeatherIcon
                                  style={{ width: "26px", height: "23px" }}
                                  icon="star"
                                />
                              </label>
                              <Field
                                id="star2"
                                name="star"
                                type="radio"
                                value="2"
                                className="radio-btn hide"
                              />
                              <label htmlFor="star2">
                                <FeatherIcon
                                  style={{ width: "26px", height: "23px" }}
                                  icon="star"
                                />
                              </label>
                              <Field
                                id="star1"
                                name="star"
                                type="radio"
                                value="1"
                                className="radio-btn hide"
                              />
                              <label htmlFor="star1">
                                <FeatherIcon
                                  style={{ width: "26px", height: "23px" }}
                                  icon="star"
                                />
                              </label>
                              <div className="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <InputField
                        label={"Review Headline:"}
                        name={"title"}
                        type={"text"}
                        placeholder=" Review Headline"
                      />
                      <TextArea
                        label={"Write a Review :"}
                        rows="3"
                        className="form-control"
                        name="description"
                      />

                      <div className="text-right">
                        <Button
                          type="submit"
                          title={"Submit Review"}
                          variant={"btn-light-green"}
                          isLoading={isLoading}
                          iconRight
                        />
                      </div>
                    </Form>
                  </Formik>
                ) : (
                  <button
                    onClick={showReviewForm}
                    type="button"
                    className="btn btn-light-green mt-1"
                  >
                    Write a Review
                  </button>
                )}

                <div className="small-header header-bottom-grey">
                  <h3>Product Reviews :</h3>
                </div>
                <div className="review-section">
                  {pData?.reviews.length === 0 ? (
                    <div className="review-section">
                      <div style={{ fontSize: "18px" }} className="text-center">
                        No reviews available
                      </div>
                    </div>
                  ) : (
                    pData?.reviews.map((item) => {
                      if (item?.approved === "Approved")
                        return (
                          <div className="review-container" key={item?.name}>
                            <div className="review-header">
                              <div className="review-icon">
                                <FeatherIcon
                                  style={{ width: "24px", height: "24px" }}
                                  icon="user"
                                />
                              </div>
                              <div className="name"> {item?.name} </div>
                            </div>
                            <div className="star-area mb-1">
                              {item?.ratingValue}{" "}
                              <FeatherIcon
                                style={{
                                  color: "#FFC741",
                                  width: "20px",
                                  height: "20px",
                                }}
                                icon="star"
                              />
                              <span className="review-title">
                                “ {item?.title} ”
                              </span>
                            </div>

                            <div>
                              <p> {item?.description} </p>
                            </div>

                            <span className="date">
                              Posted on {dateFormat(item?.date)}
                            </span>
                          </div>
                        );
                      else return null;
                    })
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-single-details">
                <div className="header-bottom-grey">
                  <h1 className="product-title">{pData?.title}</h1>
                </div>
                <div className="star-area">
                  {ratingAvg !== 0 && (
                    <>
                      {ratingAvg}
                      <FeatherIcon
                        style={{
                          color: "#FFC741",
                          width: "20px",
                          height: "20px",
                        }}
                        icon="star"
                      />
                    </>
                  )}
                  <span className="product-code">
                    <span className="grey-color">
                      Product Code: {pData?.code}
                    </span>
                  </span>
                  <div className="specs-box">
                    <span className="left">Description:</span>
                    <span className="right"> {pData?.description} </span>
                  </div>
                  <div className="specs-box">
                    <span className="left">Category:</span>
                    <span className="right">{" " + pData?.category}</span>
                  </div>
                  {pData?.minimum_quantity && (
                    <div className="specs-box">
                      <span className="left">Min Order: </span>
                      <span className="right">
                        {pData?.minimum_quantity} pcs
                      </span>
                    </div>
                  )}

                  <div className="specs-box">
                    <span className="left">Colours Available: </span>
                    <span className="right">
                      {pData?.colours ? <>{pData?.colours}</> : <>Default</>}
                    </span>
                  </div>
                  {anyError && (
                    <div className="specs-box warning-color mb-1">
                      **"Buy Online Now" option not available.
                    </div>
                  )}

                  <div className="row">
                    {pData?.discontinued === "1" && (
                      <div className="specs-box warning-color mb-1">
                        **Please note this product has been discontinued.
                      </div>
                    )}
                    {pData?.status !== "1" && (
                      <div className="specs-box warning-color mb-1">
                        **Please note this product has been disabled.
                      </div>
                    )}
                    {pData?.discontinued === "1" || pData?.status !== "1" || (
                      <>
                        {anyError !== "" && (
                          <div className="col-md-6 mt-2">
                            <Button
                              id="buyOnlineNowBtn"
                              onClick={toggleOpenOrderSection}
                              type="button"
                              title={"BUY ONLINE NOW"}
                              variant={"btn-orange-full"}
                            />
                          </div>
                        )}

                        <div className="col-md-6 mt-2">
                          <Button
                            id="expressQuoteBtn"
                            onClick={toggleOpenQuote}
                            type="button"
                            title={"QUICK QUOTE"}
                            variant={"btn-light-green-full"}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <>
                    <div
                      ref={quoteRef}
                      className={`quoteview ccontent ${
                        openQuote ? "open" : ""
                      }`}
                    >
                      <div className="container">
                        <QuoteMultiStep
                          code={pcode}
                          data={pData}
                          scroll={scrollHandle}
                          toggleOpenQuoteSection={toggleOpenQuote}
                          quoteToggleModal={quoteToggleModal}
                        />
                      </div>
                    </div>

                    <div
                      ref={orderRef}
                      className={`orderview ocontent ${
                        openOrder ? "open" : ""
                      }`}
                    >
                      <div className="container">
                        <OrderMultiStep
                          code={pcode}
                          name={pData?.title}
                          data={pData}
                          scroll={scrollHandleOrder}
                          toggleLGShow={toggleLGShow}
                        />
                      </div>
                    </div>
                  </>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="product-single-tabs mt-3">
                        <Tabs className={"main-tabs"}>
                          <TabList>
                            {anyError !== "" && <Tab>Price table</Tab>}
                            <Tab>Specification</Tab>
                            {window.innerWidth < 992 && <Tab>Reviews</Tab>}
                          </TabList>

                          {anyError !== "" && (
                            <TabPanel>
                              <PriceTable tData={pData?.tData} />
                            </TabPanel>
                          )}
                          <TabPanel>
                            <div className="product-reviews-content">
                              {pData?.dimensions && (
                                <div className="specs-box-2">
                                  <span className="left">Dimensions :</span>
                                  <span className="right">
                                    {" "}
                                    {pData?.dimensions}
                                  </span>
                                </div>
                              )}

                              {/* <div className="specs-box-2">
                            <span className="left">Print Position :</span>
                            <span className="right"> $printPosition; </span>
                          </div> */}
                              {pData?.lead_time && (
                                <div className="specs-box-2">
                                  <span className="left">
                                    Standard Lead Time :
                                  </span>
                                  <span className="right">
                                    {" "}
                                    {pData?.lead_time} working days
                                  </span>
                                </div>
                              )}
                              {pData?.express_lead_time && (
                                <div className="specs-box-2">
                                  <span className="left">
                                    Express Lead Time :
                                  </span>
                                  <span className="right">
                                    {" "}
                                    {pData?.express_lead_time} working days
                                  </span>
                                </div>
                              )}
                              {pData?.package_type && (
                                <div className="specs-box-2">
                                  <span className="left">Package Type :</span>
                                  <span className="right">
                                    {" "}
                                    {pData?.package_type}
                                  </span>
                                </div>
                              )}
                              {pData?.minimum_quantity && (
                                <div className="specs-box-2">
                                  <span className="left">
                                    Minimum Quantity :
                                  </span>
                                  <span className="right">
                                    {" "}
                                    {pData?.minimum_quantity}
                                  </span>
                                </div>
                              )}
                              {pData?.carton_weight && (
                                <div className="specs-box-2">
                                  <span className="left">Carton Weight :</span>
                                  <span className="right">
                                    {" "}
                                    {pData?.carton_weight}
                                  </span>
                                </div>
                              )}
                              {pData?.carton_quantity && (
                                <div className="specs-box-2">
                                  <span className="left">
                                    Carton Quantity :
                                  </span>
                                  <span className="right">
                                    {" "}
                                    {pData?.carton_quantity}
                                  </span>
                                </div>
                              )}

                              {pData?.longdescription && (
                                <div className="specs-box-2">
                                  <span className="left">Description :</span>
                                  <span className="right">
                                    {" "}
                                    {pData?.longdescription}
                                  </span>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          {window.innerWidth < 992 && (
                            <TabPanel>
                              <h2>Reviews</h2>
                            </TabPanel>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuoteModal show={qshow} close={quoteToggleModal} />
      <LoginRegModal
        show={lgshow}
        close={toggleLGShow}
        logOpen={logOpen}
        regOpen={regOpen}
      />
      <LoginModal show={lshow} close={lhandleClose} regOpen={regOpen} />
      <RegisterModal show={rshow} close={rhandleClose} logOpen={logOpen} />
    </div>
  );
}

export default ProductDetail;
