import { ErrorMessage, Field } from "formik";

export const InputField = ({
  children,
  type,
  name,
  label,
  placeholder,
  className,
  value,
  disabled = false,
  onChange,
}) => {
  return (
    <div className="outer-search mb-1">
      {label && <label htmlFor={name}>{label}</label>}
      {onChange ? (
        <Field
          id={name}
          className={className}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        <Field
          id={name}
          className={className}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}

      <ErrorMessage className="errorFeild" name={name} component="div" />
      {children}
    </div>
  );
};
