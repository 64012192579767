import { gUrl } from "./constants";

export const structuredDataOrg = {
  "@context": "https://schema.org",
  "@type": "Organization",
  url: gUrl,
  logo: `${gUrl}/assets/images/logos/promotional-gifts-logo.png`,
};

export const structuredDataOrgHomeStore = {
  "@context": "https://schema.org",
  "@type": "Store",
  image: [`${gUrl}/assets/images/logos/promotional-gifts-logo.png`],
  "@id": gUrl,
  name: "Promotional Products & Corporate Gifts in UK",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Turks Farm, Pink Ln",
    addressLocality: "Malmesbury",
    addressRegion: "Swindon",
    postalCode: "SN16 9GR",
    addressCountry: "UK",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: 51.5815083,
    longitude: -2.1001124,
  },
  url: gUrl,
  priceRange: "$$",
  telephone: "+44(0)1666826226",
  openingHoursSpecification: [
    {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      opens: "09:00",
      closes: "17:15",
    },
  ],
};

export const structuredDataSearch = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  url: gUrl,
  potentialAction: {
    "@type": "SearchAction",
    target: `${gUrl}/search?search={search_term_string}`,
    "query-input": "required name=search_term_string",
  },
};
