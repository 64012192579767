import { Link, useParams } from "react-router-dom";
import "./index.css";
import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { ProductLoader } from "../../utils/loaders";
import { gUrl } from "../../utils/constants";

function Subcategories({ cData }) {
  return (
    <div className="container mb-2">
      <div className="row">
        <div className="col-md-12">
          <h2 className="header-1 mt-1 mb-2">SUB CATEGORIES</h2>
        </div>
        {cData?.subCategories &&
          cData?.subCategories.length > 0 &&
          cData?.subCategories.map((item) => {
            return (
              <div
                className="col-lg-3 col-md-3 col-6 mt-1 mb-2 "
                key={item?.url}
              >
                <div className="subBox">
                  <Link to={item?.url}>
                    {item?.image ? (
                      <img
                        src={`${gUrl}${item?.image}`}
                        alt={item?.cat}
                        title={item?.cat}
                      />
                    ) : (
                      <img
                        src={`${item?.bacImg}`}
                        alt={item?.cat}
                        title={item?.cat}
                      />
                    )}
                  </Link>
                  <Link className="btn" to={item?.url}>
                    {item?.cat}
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default function Category() {
  const { mcategory } = useParams();
  const { data: cData } = useFetch(`category?mcat=${mcategory}`);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: cData?.categoryName, to: cData?.url },
  ];

  return (
    <div>
      {cData === null ? (
        <div className="container mt-5 mb-5">
          <ProductLoader />
        </div>
      ) : (
        <>
          <div className="container">
            <Breadcrumb array={BreadcrumbData} />
          </div>
          <div className="container">
            <div className="row mb-2 promo-header-2">
              <div className="col-3 col-lg-2">
                <img
                  className="img-responsive"
                  src={gUrl + cData?.catImage}
                  alt={cData?.categoryName}
                  title={cData?.categoryName}
                />
              </div>
              <div className="col-9 col-lg-10">
                <h1>{cData?.categoryName} </h1>
                <p>{cData?.categoryDescription}</p>
              </div>
            </div>
            <Subcategories cData={cData} />
          </div>
        </>
      )}
    </div>
  );
}
