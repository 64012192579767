import mockMenu from "./menu.json";
import mockSlug from "./slug.json";
import mockCategories from "./categories.json";
import mockBanners from "./banners.json";
import mockGiftFinder from "./gift-finder.json";
import mockProductDetails from "./product-details.json";

export const MockMenu = mockMenu;
export const MockSlug = mockSlug;
export const MockCategories = mockCategories;
export const MockBanners = mockBanners;
export const MockGiftFinder = mockGiftFinder;
export const MockProductDetails = mockProductDetails;
