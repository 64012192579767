import { useNavigate } from "react-router-dom";
import "./index.css";
import useFetch from "../../hooks/useFetch";
import { ProfileMenu } from "../../components/profile-menu";
import { InputField } from "../../components/input-field";
import { Field, Form, Formik } from "formik";
import { SelectField } from "../../components/select-field";
import { CenterContentLoader } from "../../utils/loaders";
import {
  authHeader,
  errorCode,
  fetchAddresses,
  notifyFail,
  notifySuccess,
} from "../../utils";
import { apiSrc } from "../../utils/constants";
import { useState } from "react";
import { Button } from "../../components/button";
import { validationSchemaAddress } from "../../utils/validationSchema";

export default function PersonalDetails() {
  const navigate = useNavigate();
  const { data: personalData } = useFetch("personal", "POST", {
    request_type: "updateUser",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [timer, setTimer] = useState(null);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    { name: "User Profile", to: "/personal-details" },
    {
      name: "active",
      to: "My Account",
    },
  ];

  const handleSearch = (e) => {
    const value = e.target.value;
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        handleInputChangeAfterDelay(value);
        setTimer(null); // reset the timer
      }, 1000)
    );
  };

  const handleInputChangeAfterDelay = (value) => {
    fetchAddresses(value)
      .then((data) => {
        setAddresses(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {personalData === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {personalData?.status === 200 ? (
            <ProfileMenu BreadcrumbData={BreadcrumbData}>
              <div className="small-header header-bottom-grey mb-2">
                <h3>Account Holder Details:</h3>
              </div>
              <Formik
                initialValues={{
                  request_type: "updateUser",
                  billingCheckbox: true,
                  deliveryCheckbox: true,
                  fname: personalData?.message?.person?.fname,
                  lName: personalData?.message?.person?.lName,
                  email: personalData?.message?.person?.email,
                  telephone: personalData?.message?.person?.telephone,
                  mCompany: personalData?.message?.address?.companyName,
                  address1: personalData?.message?.address?.address1,
                  address2: personalData?.message?.address?.address2,
                  town: personalData?.message?.address?.town,
                  county: personalData?.message?.address?.county,
                  postcode: personalData?.message?.address?.postcode,
                }}
                validationSchema={validationSchemaAddress()}
                onSubmit={(values) => {
                  const payload = values;

                  const requestOptions = {
                    method: "POST",
                    headers: authHeader(),
                    body: JSON.stringify(payload),
                  };
                  setIsLoading(true);
                  fetch(`${apiSrc}personal_update`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                      if (data?.status === 200) {
                        notifySuccess(data?.message);
                      } else {
                        notifyFail(
                          data?.message
                            ? data?.message
                            : "Something went wrong!"
                        );
                      }
                    })
                    .catch((error) => {
                      notifyFail("Something went wrong!");
                      console.error(error);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              >
                {(props) => {
                  const { setFieldValue } = props;

                  const handleSelect = (e) => {
                    const value = e.target.value;
                    if (value !== "") {
                      const address = JSON.parse(value);
                      setFieldValue("mCompany", address?.organisation);
                      setFieldValue("address1", address?.addressline1);
                      setFieldValue("address2", address?.addressline2);
                      setFieldValue("town", address?.posttown);
                      setFieldValue("county", address?.county);
                      setFieldValue("postcode", address?.postcode);
                    }
                  };

                  return (
                    <Form className="m-0">
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            name={"fname"}
                            type={"text"}
                            label={"First Name:"}
                            placeholder="First Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            name={"lName"}
                            type={"text"}
                            label={"Last Name:"}
                            placeholder="Last Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            name={"email"}
                            type={"email"}
                            label={"Email:"}
                            placeholder="Email"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            name={"telephone"}
                            type={"number"}
                            label={"Phone No:"}
                            placeholder="Phone No"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="small-header header-bottom-grey mb-2">
                            <h3>Address Details:</h3>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="" id="address_finder">
                            <div className="form-group">
                              <label
                                htmlFor="postcoder_autocomplete"
                                id="postcoder_autocomplete_label"
                              >
                                Type in your address or postcode :
                              </label>
                              <input
                                id="postcoder_autocomplete"
                                type="text"
                                className="form-control select-light"
                                onChange={handleSearch}
                              />
                            </div>
                            {addresses?.length > 0 && (
                              <select
                                className="cselect cselectstyle"
                                onChange={handleSelect}
                              >
                                <option value="">Select an address</option>
                                {addresses.map((address, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={JSON.stringify(address)}
                                    >
                                      {address.summaryline}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                          <div className="mb-2 header-bottom-grey"></div>
                        </div>
                        <div className="col-md-12">
                          <InputField
                            name={"mCompany"}
                            type={"text"}
                            label={"Company"}
                            placeholder="Company"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            id={"address1"}
                            name={"address1"}
                            type={"text"}
                            label={"Address Line 1"}
                            placeholder="Address Line 1"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            name={"address2"}
                            type={"text"}
                            label={"Address Line 2"}
                            placeholder="Address Line 2"
                          />
                        </div>

                        <div className="col-md-6">
                          <InputField
                            name={"town"}
                            type={"text"}
                            label={"City / Town"}
                            placeholder="City / Town"
                          />
                        </div>

                        <div className="col-md-6">
                          <InputField
                            name={"county"}
                            type={"text"}
                            label={"County / State / Region"}
                            placeholder="County / State / Region"
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            name={"postcode"}
                            type={"text"}
                            label={"Postcode / Zipcode"}
                            placeholder="Postcode / Zipcode"
                          />
                        </div>

                        <div className="col-md-6  ">
                          <SelectField
                            label={"Country"}
                            name={"Country"}
                            onChange={(e) => {}}
                          >
                            <option value="GB">United Kingdom</option>
                          </SelectField>
                        </div>
                      </div>

                      <div className="custom-control custom-checkbox mt-1 mb-2">
                        <Field
                          name="billingCheckbox"
                          type="checkbox"
                          className="custom-control-input"
                          id="change-billing-checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="change-billing-checkbox"
                        >
                          Use this as my invoice address.
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox mt-1 mb-2">
                        <Field
                          name="deliveryCheckbox"
                          type="checkbox"
                          className="custom-control-input"
                          id="change-delivery-checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="change-delivery-checkbox"
                        >
                          Use this as my delivery address.
                        </label>
                      </div>

                      <div className="form-footer">
                        <button
                          className="btn btn-orange"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          <i className="icon-angle-double-left"></i>Back
                        </button>

                        <div className="form-footer-right">
                          <Button
                            className="pt-2 pb-2 text-center"
                            variant={"btn-light-green-full"}
                            type="submit"
                            title={"Save"}
                            isLoading={isLoading}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </ProfileMenu>
          ) : (
            <>{errorCode(personalData?.status, personalData?.message)}</>
          )}
        </>
      )}
    </div>
  );
}
