import Breadcrumb from "../../components/breadcrumb";
import { stepsTexts } from "../../utils/constants";

import { Steps } from "../../components/stepper";
import "./index.css";
import { Link } from "react-router-dom";

export default function OrderFailed() {
  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Checkout",
      to: "/checkout",
    },
    {
      name: "Billing",
      to: "/billing",
    },
    {
      name: "Payment",
      to: "/payment",
    },
  ];

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Steps
                active={3}
                steps={[
                  ...stepsTexts.slice(0, 3),
                  { label: "4. Order Failed" },
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-2 mb-4">
              <div className="order-summary">
                <p>
                  <h3 className="step-title mb-2">
                    We’re Sorry! Your order could not be processed.
                  </h3>
                  <p>
                    Dear Customer, we’re having trouble processing your order.{" "}
                    <br />
                    You have not been charged for this transaction, please
                    follow the instructions and try again{" "}
                  </p>
                  <Link to="/payment" className="btn-link">
                    Try Again
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
