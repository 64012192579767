import ContentLoader from "react-content-loader";

const size = 30;

export const HeaderLoader = (props) => (
  <ContentLoader
    width={window.innerWidth - size}
    height={window.innerWidth < 500 ? 150 : 300}
    viewBox={`0 0 320 54`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="27" cy="27" r="18" />
    <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
    <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
    <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
    <circle cx="305" cy="27" r="8" />
    <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
    <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
  </ContentLoader>
);

export const CenterContentLoader = (props) => (
  <ContentLoader
    width={window.innerWidth - size}
    height={300}
    viewBox="0 0 1000 300"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="4" y="8" rx="3" ry="3" width="7" height="288" />
    <rect x="6" y="289" rx="3" ry="3" width="669" height="8" />
    <rect x="670" y="9" rx="3" ry="3" width="6" height="285" />
    <rect x="55" y="42" rx="16" ry="16" width="274" height="216" />
    <rect x="412" y="113" rx="3" ry="3" width="102" height="7" />
    <rect x="402" y="91" rx="3" ry="3" width="178" height="6" />
    <rect x="405" y="139" rx="3" ry="3" width="178" height="6" />
    <rect x="416" y="162" rx="3" ry="3" width="102" height="7" />
    <rect x="405" y="189" rx="3" ry="3" width="178" height="6" />
    <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
    <rect x="406" y="223" rx="14" ry="14" width="72" height="32" />
    <rect x="505" y="224" rx="14" ry="14" width="72" height="32" />
    <rect x="376" y="41" rx="3" ry="3" width="231" height="29" />
  </ContentLoader>
);

export const ProductLoader = (props) => (
  <ContentLoader viewBox="0 0 800 400" height={400} width={800} {...props}>
    <circle cx="472" cy="159" r="7" />
    <rect x="487" y="154" rx="5" ry="5" width="220" height="10" />
    <circle cx="472" cy="190" r="7" />
    <rect x="487" y="184" rx="5" ry="5" width="220" height="10" />
    <circle cx="472" cy="219" r="7" />
    <rect x="487" y="214" rx="5" ry="5" width="220" height="10" />
    <circle cx="472" cy="249" r="7" />
    <rect x="487" y="244" rx="5" ry="5" width="220" height="10" />
    <rect x="64" y="18" rx="0" ry="0" width="346" height="300" />
    <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
    <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
    <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
    <rect x="10" y="20" rx="0" ry="0" width="40" height="44" />
    <rect x="10" y="80" rx="0" ry="0" width="40" height="44" />
    <rect x="10" y="140" rx="0" ry="0" width="40" height="44" />
    <rect x="194" y="329" rx="0" ry="0" width="0" height="0" />
    <rect x="192" y="323" rx="0" ry="0" width="0" height="0" />
    <rect x="185" y="323" rx="0" ry="0" width="0" height="0" />
    <rect x="10" y="200" rx="0" ry="0" width="40" height="44" />
    <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
    <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
    <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
    <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
    <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
    <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
    <rect x="470" y="99" rx="0" ry="0" width="70" height="30" />
    <rect x="560" y="99" rx="0" ry="0" width="70" height="30" />
  </ContentLoader>
);
