import Breadcrumb from "../../components/breadcrumb";
import useFetch from "../../hooks/useFetch";
import { CenterContentLoader } from "../../utils/loaders";
import { apiSrc, stepsTexts } from "../../utils/constants";
import {
  authHeader,
  btWarning,
  errorCode,
  formatNumber,
  MySwal,
  notifyFail,
} from "../../utils";
import { Steps } from "../../components/stepper";
import { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Paypal } from "../../components/paypal";
import { Button } from "../../components/button";

export default function Payment() {
  const navigate = useNavigate();
  const { data: pdata } = useFetch("personal", "POST", {
    request_type: "checkout",
  });

  const [paymentAddress, setPaymentAddress] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState({});

  useEffect(() => {
    if (pdata?.message?.cartItem?.paymentAddress)
      setPaymentAddress(JSON.parse(pdata?.message?.cartItem?.paymentAddress));
    if (pdata?.message?.cartItem?.shippingAddress)
      setDeliveryAddress(JSON.parse(pdata?.message?.cartItem?.shippingAddress));
  }, [pdata]);

  const [isLoadingStripe, setIsLoadingStripe] = useState(false);

  const BreadcrumbData = [
    { name: "Home", to: "/" },
    {
      name: "Checkout",
      to: "/checkout",
    },
    {
      name: "Billing",
      to: "/shipping",
    },
    {
      name: "Payment",
      to: "/payment",
    },
  ];

  function bankTransfer() {
    MySwal.fire(btWarning).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            request_type: "bankTransferOnline",
          }),
        };
        fetch(`${apiSrc}personal_update`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === 200) {
              navigate("/order-complete/" + data?.message?.oid);
            } else {
              notifyFail(
                data?.message ? data?.message : "Something went wrong!"
              );
            }
          })
          .catch((error) => {
            notifyFail("Something went wrong!");
            console.error(error);
          })
          .finally(() => {});
      }
    });
  }

  function onAccount() {
    MySwal.fire(btWarning).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            request_type: "buyOnAccountOnline",
          }),
        };
        fetch(`${apiSrc}personal_update`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === 200) {
              navigate("/order-complete/" + data?.message?.oid);
            } else {
              notifyFail(
                data?.message ? data?.message : "Something went wrong!"
              );
            }
          })
          .catch((error) => {
            notifyFail("Something went wrong!");
            console.error(error);
          })
          .finally(() => {});
      }
    });
  }

  function onApprovePaypal(data) {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "PO",
        details: data,
      }),
    };
    fetch(`${apiSrc}personal_update`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          navigate("/order-complete/" + data?.message?.oid);
        } else {
          notifyFail(data?.message ? data?.message : "Something went wrong!");
        }
      })
      .catch((error) => {
        notifyFail("Something went wrong!");
        console.error(error);
      })
      .finally(() => {});
  }

  function stripePayment() {
    setIsLoadingStripe(true);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        request_type: "stripe_payment",
      }),
    };
    fetch(`${apiSrc}personal_update`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 200) {
          window.location.href = data?.message?.redirect_url;
        } else {
          notifyFail(data?.message ? data?.message : "Something went wrong!");
        }
      })
      .catch((error) => {
        notifyFail("Something went wrong!");
        console.log("Response:", error);
        console.error(error);
      })
      .finally(() => {
        setIsLoadingStripe(false);
      });
  }

  return (
    <div>
      <div className="container">
        <Breadcrumb array={BreadcrumbData} />
      </div>
      {pdata === null ? (
        <div className="container mt-5 mb-5">
          <CenterContentLoader />
        </div>
      ) : (
        <>
          {pdata?.status !== 200 ? (
            errorCode(pdata?.status, pdata?.message)
          ) : (
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Steps active={2} steps={stepsTexts} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-lg-8">
                    <div className="order-summary mt-0">
                      <h3
                        className="mb-2"
                        style={{
                          borderBottom: "2px solid #dee2e6",
                          paddingBottom: "5px",
                        }}
                      >
                        Order Summary
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="checkout-info-box">
                            <h3
                              className="step-title"
                              style={{ fontSize: "15px" }}
                            >
                              Invoice Address:
                              <Link
                                to="/shipping#invoice"
                                className="step-title-edit"
                              >
                                <span className="sr-only">Edit</span>
                                <FeatherIcon
                                  style={{
                                    color: "#39C4BA",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                  icon="edit-2"
                                />
                              </Link>
                            </h3>

                            <address>
                              <b>
                                {paymentAddress?.firstname}{" "}
                                {paymentAddress?.lastname}
                              </b>
                              <br />
                              {paymentAddress?.company && (
                                <>
                                  {paymentAddress?.company} <br />
                                </>
                              )}
                              {paymentAddress?.address1 && (
                                <>
                                  {paymentAddress?.address1} <br />
                                </>
                              )}
                              {paymentAddress?.address2 && (
                                <>
                                  {paymentAddress?.address2} <br />
                                </>
                              )}
                              {paymentAddress?.town && (
                                <>
                                  {paymentAddress?.town}{" "}
                                  {paymentAddress?.county} <br />
                                </>
                              )}
                              {paymentAddress?.postcode && (
                                <>
                                  {paymentAddress?.postcode} <br />
                                </>
                              )}
                              United Kingdom <br />
                              Email:{" "}
                              <a href={`mailto: ${paymentAddress?.email} `}>
                                {paymentAddress?.email}
                              </a>
                              <br />
                              Tel: {paymentAddress?.phone}
                            </address>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="checkout-info-box">
                            <h3
                              className="step-title"
                              style={{ fontSize: "15px" }}
                            >
                              Delivery Address:
                              <Link
                                to="/shipping#invoice"
                                className="step-title-edit"
                              >
                                <span className="sr-only">Edit</span>
                                <FeatherIcon
                                  style={{
                                    color: "#39C4BA",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                  icon="edit-2"
                                />
                              </Link>
                            </h3>

                            <address>
                              <b>
                                {deliveryAddress?.firstname}{" "}
                                {deliveryAddress?.lastname}
                              </b>
                              <br />
                              {deliveryAddress?.company && (
                                <>
                                  {deliveryAddress?.company} <br />
                                </>
                              )}
                              {deliveryAddress?.address1 && (
                                <>
                                  {deliveryAddress?.address1} <br />
                                </>
                              )}
                              {deliveryAddress?.address2 && (
                                <>
                                  {deliveryAddress?.address2} <br />
                                </>
                              )}
                              {deliveryAddress?.town && (
                                <>
                                  {deliveryAddress?.town}{" "}
                                  {deliveryAddress?.county} <br />
                                </>
                              )}
                              {deliveryAddress?.postcode && (
                                <>
                                  {deliveryAddress?.postcode} <br />
                                </>
                              )}
                              United Kingdom <br />
                              Email:{" "}
                              <a href={`mailto: ${deliveryAddress?.email} `}>
                                {deliveryAddress?.email}
                              </a>
                              <br />
                              Tel: {deliveryAddress?.phone}
                            </address>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="order-summary">
                                <h2 className="step-title mb-2">Product</h2>
                                <figure className="product-image-container pr-2">
                                  <a href=" echo $url; ">
                                    <img
                                      style={{ width: "100px" }}
                                      src={pdata?.message?.product?.image}
                                      alt="product"
                                    />
                                  </a>
                                </figure>
                                <h2 className="product-title">
                                  <a href=" echo $url; ">
                                    {pdata?.message?.product?.title}
                                  </a>
                                </h2>
                                <div className="pt-2">
                                  <p>
                                    <b>Qty:</b>{" "}
                                    {pdata?.message?.cartItem?.quantity} <br />
                                    <b>Branding:</b>{" "}
                                    {pdata?.message?.cartItem?.branding} <br />
                                    <b>Product Colour:</b>{" "}
                                    {pdata?.message?.cartItem?.colour}
                                    <br />
                                    <b>No of Colours:</b>{" "}
                                    {pdata?.message?.cartItem?.noOfColours}
                                    <br />
                                    <b>Print Position:</b>{" "}
                                    {pdata?.message?.cartItem?.printPosition}{" "}
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="order-summary">
                                <h3>Summary</h3>
                                <table className="table table-cart-pricing mt-2 mb-0">
                                  <thead>
                                    <tr>
                                      <th>Qty</th>
                                      <th className="text-right">Unit Price</th>
                                      <th className="text-right">Pricing</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span id="quantity2Span">
                                          {pdata?.message?.cartItem?.quantity}
                                        </span>
                                      </td>
                                      <td className="text-right">
                                        £
                                        <span id="color_costSpan">
                                          {pdata?.message?.cartItem?.color_cost}
                                        </span>
                                      </td>
                                      <td className="text-right">
                                        £
                                        <span id="SubTotalEachSpan">
                                          {formatNumber(
                                            parseFloat(
                                              pdata?.message?.cartItem?.quantity
                                            ) *
                                              parseFloat(
                                                pdata?.message?.cartItem
                                                  ?.color_cost
                                              )
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td></td>
                                      <td className="header text-right">
                                        Origination:
                                      </td>
                                      <td className="text-right">
                                        £
                                        <span id="originationSpan">
                                          {formatNumber(
                                            parseFloat(
                                              pdata?.message?.cartItem
                                                ?.origination
                                            )
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td className="header text-right">
                                        Delivery:
                                      </td>
                                      <td className="text-right">
                                        £
                                        <span id="carriage_costSpan">
                                          {formatNumber(
                                            parseFloat(
                                              pdata?.message?.cartItem
                                                ?.carriage_cost
                                            )
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td className="header text-right">
                                        Sub-total:
                                      </td>
                                      <td className="text-right">
                                        £
                                        <span id="totalCostExVatSpan">
                                          {formatNumber(
                                            parseFloat(
                                              pdata?.message?.cartItem
                                                ?.totalCostExVat
                                            )
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td className="header text-right">
                                        VAT 20%
                                      </td>
                                      <td className="text-right">
                                        £
                                        <span id="vatSpan">
                                          {formatNumber(
                                            parseFloat(
                                              pdata?.message?.cartItem?.vat
                                            )
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      style={{ borderTop: "2px solid #39C4BA" }}
                                    >
                                      <td></td>
                                      <td className="primary-header text-right">
                                        Total:
                                      </td>
                                      <td className="primary-header text-right">
                                        £
                                        {formatNumber(
                                          parseFloat(
                                            pdata?.message?.cartItem
                                              ?.totalCostIncVat
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4">
                    <div className="checkout-info-box">
                      <h3 className="step-title">Payment</h3>
                      <Button
                        className=" text-center"
                        variant={"btn-black w-100"}
                        onClick={stripePayment}
                        type="button"
                        title={
                          <>
                            <i className="far fa-credit-card"></i> CARD (Stripe)
                          </>
                        }
                        isLoading={isLoadingStripe}
                      />

                      <p className="text-center mb-0">OR</p>
                      <Paypal onApprove={onApprovePaypal} />
                      <div id="paypal-button-container"></div>
                      {pdata?.message?.buyOnAccount && (
                        <>
                          <p className="text-center mb-0">OR</p>
                          <button
                            className="btn btn-orange-full p-3 mt-1"
                            onClick={onAccount}
                          >
                            <i className="far fa-building"></i> Buy On Account
                          </button>
                        </>
                      )}
                      <p className="text-center mb-0">OR</p>
                      <button
                        className="btn btn-light-green-full p-3"
                        onClick={bankTransfer}
                      >
                        <i className="fas fa-university"></i> Bank transfer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
